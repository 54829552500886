import {Provider} from "react-redux";
import store from "../../storeChannel";
import React from "react";
import ChannelApp from "./ChannelApp";
import '../../css/tailwind.css'
import '../../css/main.css'
import '../../css/animate.css'
import '../../css/_components.css'

const App = () => {

    return (
        <Provider store={store}>
            <ChannelApp />
        </Provider>
    )

}

export default App;
