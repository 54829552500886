import React from 'react'

const CenteredLayout = ({children}) => (
    <div
        data-background={'light'}
        data-layout="centered"
        className="w-full min-h-screen h-max  flex items-center justify-center bg-grey-100 lg:p-10 text-sm">
        {children}
    </div>
)

export default CenteredLayout
