import React, {useEffect, useState} from 'react'
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {apiCreateSuperPartner} from "../action";
import {useNavigate} from "react-router-dom"
import toast from "react-hot-toast"
import {switchError} from "../../../../api/api";
import SelectField from "../../../../components/FinalForm/SelectField/SelectField";
import {apiGetSubjects} from "../../Subjects/action";
import {useTranslation} from "react-i18next";

const SUPER_PARTNER_EXISTS = "SUPER_PARTNER_EXISTS"

const CreateSuperPartner = () => {

    let [loading, setLoading] = useState(false)
    let [subjects, setSubjects] = useState(null)

    const navigate = useNavigate()
    const {t} = useTranslation()


    useEffect(() => {
        apiGetSubjects((data) => setSubjects(data))
    }, [])

    const onSubmit = (values) => {
        setLoading(true)
        apiCreateSuperPartner(values, () => {
            navigate('/admin/system/super-partners')
            toast.success(t("superPartners.created"))
        }, err => {
            setLoading(false)
            switchError(err, {
                400: [
                    {type: SUPER_PARTNER_EXISTS, message: t('superPartners.existYet')}
                ]
            })
        })
    }

    return (
        <>
            <SectionTitle title={t('basics.system')} subtitle={t('superPartners.add')}/>
            <Widget>

                <Form onSubmit={onSubmit} render={({handleSubmit}) => (
                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                            <div className='w-full'>
                                <TextField label={t('superPartners.nameOf')} name={'name'} inputType={'text'}
                                           validate={value => !value}/>
                            </div>
                            <div className='w-full'>
                                <SelectField name={'subjects'} label={t('subjects.plural')} isMulti={true} validate={true}
                                             loading={!subjects} placeholder={t('placeholders.subject')}
                                             options={subjects}/>
                            </div>
                        </div>
                        <div className='text-right'>
                            <Button rounded={true} size={'default'} onClick={handleSubmit} text={t('basics.save')}
                                    loading={loading}/>
                        </div>

                    </>
                )}/>
            </Widget>
        </>
    )
}

export default CreateSuperPartner;
