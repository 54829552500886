import React from "react";
import PhoneInput from 'react-phone-input-2'
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const TelephoneInput = ({...props}) => {
    const {t} = useTranslation()

    const _renderError = (meta) => {
        if (props.instantRenderError) {
            if (meta.error) {
                return <div className='form-error'>{meta.error === true ? t("errors.requireField") : meta.error}</div>
            }
        } else if (props.renderErrorWithoutSubmit) {
            if (meta.modified && meta.error) {
                return <div className='form-error'>{meta.error === true ? t("errors.requireField") : meta.error}</div>
            }
        } else if (meta.touched && meta.error) {
            return <div className='form-error'>{meta.error === true ? t("errors.requireField") : meta.error}</div>
        }

        return null;
    }

    const _renderClassName = (meta) => {
        let className = `form-input `
        if (props.instantRenderError) {
            if (meta.error) {
                className += 'border-red-500 '
            }
        }
        else if (props.renderErrorWithoutSubmit) {
            if (meta.modified && meta.error) {
                className += 'border-red-500 '
            }
        } else if (meta.touched && meta.error) {
            className += 'border-red-500 '
        }

        return className;
    }

    return (
        <Field {...props} name={props.name} render={({input, meta}) => (
            <div className='form-element'>
                {props.label && <div className="form-label">{props.label}{(props.validate ? "*" : "")}</div>}
                <PhoneInput
                    {...input}
                    regions={'europe'}
                    preferredCountries={['cs']}
                    country={'cs'}
                    value={meta.modified ? (input.value) : (input.value ? input.value : '+420')}
                    specialLabel={""}
                    inputClass={_renderClassName(meta)}
                    placeholder={'+420 xxx xxx xxx'}
                />
                {_renderError(meta)}
            </div>
        )}/>
    )
}


TelephoneInput.defaultProps = {
    inputType: 'text',
    label: null,
    renderErrorWithoutSubmit: false,
    instantRenderError: false
}

TelephoneInput.propTypes = {
    inputType: PropTypes.string,
    label: PropTypes.string,
    renderErrorWithoutSubmit: PropTypes.bool,
    instantRenderError: PropTypes.bool
}

export default TelephoneInput
