import {api, apiErrorParser} from "../../../api/api";


export const apiCreateSuperPartner = (values, success, error) => {
    api.post('api/v1/super-partner', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetSuperPartners = (success, error) => {
    api.get('api/v1/super-partner')
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}
