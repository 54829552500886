import React, {useEffect, useState} from "react"

import '../../css/tailwind.css'
import '../../css/main.css'
import '../../css/animate.css'
import '../../css/_components.css'
import {useDispatch, useSelector} from "react-redux";
import MainLayout from "../../components/Layouts/MainLayout";

import Routing from "./Routing";
import Login from "./Login/Login";
import {getTokenFromLocalStorage} from "./Login/action";
import Auth from "./Auth/Auth";
import {api} from "../../api/api";
import {USER_RESET} from "../../reducers/admin/user";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";

const Token = () => {

    let [done, setDone] = useState(false)

    const {token, user} = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getTokenFromLocalStorage(dispatch)
        setDone(true)
        setErrorHandler()
    }, [])

    const setErrorHandler = () => {
        api.interceptors.response.use((response) => response, (error => {
            if (error.response.status === 403) {
                dispatch({type: USER_RESET})
                localStorage.removeItem("Authorization");
                navigate('/admin')
            } else {
                return Promise.reject(error);
            }
        }))
    }

    if (!done) return null

    if (!token) return <Login/>
    if (!user) return <Auth/>

    return (
        <MainLayout>
            <Routing/>
        </MainLayout>
    )

}

export default Token;
