import React, {useEffect, useState} from "react";
import {apiGetUserVerify, apiUserVerify} from "../action";
import CenteredLayout from "../../../../components/Layouts/CenteredLayout";
import CenteredForm from "../../../../components/core/CenteredForm";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {useNavigate, useParams} from "react-router-dom";
import VerifyError from "./VerifyError";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import LanguageDropDown from "../../../../components/Language/LanguageDropDown";

const UserVerify = () => {

    let [loading, setLoading] = useState(true)
    let [submitting, setSubmitting] = useState(false)
    let [error, setError] = useState(false)

    const {verifyString} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation();

    useEffect(() => apiGetUserVerify(verifyString, (data) => {
        setLoading(false)
    }, (err) => {
        setError(err)
        setLoading(false)
    }), [])


    const onSubmit = (values) => {
        setSubmitting(true)
        apiUserVerify(verifyString, {password: values.password}, () => {
            toast.success(t("verifyUser.passwordSet"))
            navigate('/admin')
        }, err => setError(err))
    }


    const _renderContent = () => {
        if (loading) return <ContentSpinner isSpinning={true}></ContentSpinner>
        if (error) return <VerifyError error={error}/>

        return (
            <CenteredForm
                title={t("verifyUser.verifyUser")}
                subtitle={t("verifyUser.enterYourAccessData")}
            >
                <div className={"fixed top-0 right-0 mr-2 mt-2"}>
                    <LanguageDropDown/>
                </div>
                <div className="flex flex-col">

                    <Form className={'form'} onSubmit={onSubmit} render={({handleSubmit, values}) => (
                        <form className='form flex flex-wrap w-full'>
                            <div className='w-full'>
                                <TextField name={'password'} inputType={'password'} validate={value => {
                                    if (!value) return true
                                    if (value.length < 6) return t('resetPassword.min6chars')
                                    return false
                                }} label={t('verifyUser.passwordMin6chars')}/>
                            </div>
                            <div className='w-full'>
                                <TextField name={'repeat'} inputType={'password'}
                                           label={t("resetPassword.enterPasswordAgain")}
                                           validate={value => {
                                               if (!value) return true;
                                               if (value !== values.password) return t("resetPassword.passwordsNotTheSame")
                                               return false;
                                           }}/>
                            </div>
                            <Button text={t("verifyUser.verifyAndSetPassword")} onClick={handleSubmit} rounded={true}
                                    loading={submitting}/>
                        </form>
                    )}/>
                </div>

            </CenteredForm>
        )
    }

    return (
        <CenteredLayout>
            {_renderContent()}
        </CenteredLayout>
    )
}

export default UserVerify
