import React from "react";
import Datatable from "../../../components/core/Datatable/Datatable";
import {useDispatch, useSelector} from "react-redux";
import {CHANNEL_SET_SELECTED_TICKETS} from "../../../reducers/channel/channel";
import {useTranslation} from "react-i18next";


const TicketTable = ({tickets}) => {

    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {isSubmitted, type, selectedTickets} = useSelector(state => state.channel)

    return (
        <>
            <Datatable data={tickets} columns={[
                {Header: t('tickets.sector'), accessor: 'sector'},
                {Header: t('tickets.row'), accessor: 'row'},
                {Header: t('tickets.place'), accessor: 'place'}
            ]} setSelectedItems={(items) => dispatch({type: CHANNEL_SET_SELECTED_TICKETS, payload: items})}/>
            {isSubmitted && type === 1 && selectedTickets.length === 0 && <div>
                <div className='text-red-500 text-center'>{t("tickets.selectMin1Ticket")}</div>
            </div>}
        </>
    )
}

export default TicketTable
