import {FiCalendar, FiGrid, FiStar, FiUsers, FiRepeat, FiLayers, FiBox, FiTag} from "react-icons/fi";

export const menu = [
    {
        title: 'menu.main',
        items: [],
    },
    {
        title: 'basics.system',
        isAdmin: true,
        items: [
            {
                url: '/',
                icon: <FiUsers size={20}/>,
                title: 'users.plural',
                isAdmin: true,
                items: [
                    {
                        url: 'system/users/add',
                        title: 'users.add',
                        isAdmin: true,
                        items: []
                    },
                    {
                        url: 'system/users',
                        title: 'users.list',
                        isAdmin: true,
                        items: []
                    },
                ]
            },
            {
                url: '/',
                icon: <FiGrid size={20}/>,
                title: 'subjects.plural',
                isAdmin: true,
                items: [
                    {
                        url: 'system/subjects/add',
                        title: 'subjects.add',
                        isAdmin: true,
                        items: []
                    },
                    {
                        url: 'system/subjects',
                        title: 'subjects.list',
                        isAdmin: true,
                        items: []
                    },
                ]
            },
            {
                url: '/',
                icon: <FiStar size={20}/>,
                title: 'superPartners.plural',
                isAdmin: true,
                items: [
                    {
                        url: 'system/super-partners/add',
                        title: 'superPartners.add',
                        isAdmin: true,
                        items: []
                    },
                    {
                        url: 'system/super-partners',
                        title: 'superPartners.list',
                        isAdmin: true,
                        items: []
                    },
                ]
            }
        ],
    }
]


export const superPartnerMenu = [
    {
        url: 'super-partner/events',
        icon: <FiCalendar size={20}/>,
        title: 'events.plural',
        items: []
    },
    {
        url: 'super-partner/summary',
        icon: <FiTag size={20}/>,
        title: 'summary.plural',
        items: []
    },
    {
        url: 'super-partner/orders',
        icon: <FiLayers size={20}/>,
        title: 'orders.plural',
        items: []
    },
    {
        url: 'super-partner/channels',
        icon: <FiBox size={20}/>,
        title: 'distributionChannels.distribution',
        items: []
    },
    {
        url: '/',
        icon: <FiUsers size={20}/>,
        title: 'partners.plural',
        items: [
            {
                url: 'super-partner/partners/add',
                title: 'partners.add',
                items: []
            },
            {
                url: 'super-partner/partners',
                title: 'partners.list',
                items: []
            }
        ]
    },
]


export const partnerMenu = [
    {
        url: 'partner/events',
        icon: <FiCalendar size={20}/>,
        title: 'events.plural',
        items: []
    },
    {
        url: 'partner/orders',
        icon: <FiLayers size={20}/>,
        title: 'orders.plural',
        items: []
    },
    {
        url: 'partner/channels',
        icon: <FiBox size={20}/>,
        title: 'distributionChannels.distribution',
        items: []
    },
]


export const switcher = {
    url: 'switch-partner',
    icon: <FiRepeat size={20}/>,
    title: 'partners.switchPartner',
    items: []
}

export const chooser = {
    url: 'switch-partner',
    icon: <FiRepeat size={20}/>,
    title: 'partners.select',
    items: []
}
