import React, {useState} from 'react'
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {apiCreateUser} from "../action";
import {useNavigate} from "react-router-dom"
import toast from "react-hot-toast"
import * as EmailValidator from 'email-validator';
import {switchError} from "../../../../api/api";
import {useTranslation} from "react-i18next";

const USER_EXISTS = "USER_EXISTS"

const UserCreate = () => {

    let [loading, setLoading] = useState(false)
    const {t} = useTranslation()

    const navigate = useNavigate()

    const onSubmit = (values) => {
        setLoading(true)
        apiCreateUser(values, () => {
            navigate('/admin/system/users')
            toast.success(t("users.created"))
        }, err => {
            setLoading(false)
            switchError(err, {
                400: [
                    {type: USER_EXISTS, message: t('users.existYet')}
                ]
            })
        })
    }

    return (
        <>
            <SectionTitle title={t('basics.system')} subtitle={t('users.add')}/>
            <Widget>

                <Form onSubmit={onSubmit} render={({handleSubmit}) => (
                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                            <div className='w-full'>
                                <TextField label={t('users.emailOf')} name={'username'} inputType={'email'}
                                           validate={value => {
                                               if (!value) return true;
                                               if (!EmailValidator.validate(value)) return t("errors.fieldMustBeEmail")
                                               return false;
                                           }}/>
                            </div>
                            <div className='w-full'>
                                <TextField label={t('users.passwordOf')} name={'password'} inputType={'text'}
                                           validate={value => {
                                               if (!value) return true;
                                               if (value.length < 6) return t("resetPassword.min6chars")
                                               return false
                                           }}/>
                            </div>
                        </div>
                        <div className='text-right'>
                            <Button rounded={true} size={'default'} onClick={handleSubmit} text={t('basics.save')}
                                    loading={loading}/>
                        </div>

                    </>
                )}/>
            </Widget>
        </>
    )
}

export default UserCreate;
