import React from "react";
import {useDispatch, useSelector} from "react-redux";
import SectionTitle from "../../../components/core/SectionTitle";
import Widget from "../../../components/core/Widget";
import Table from "../../../components/Table/Table";
import Button from "../../../components/Button/Button";
import {TYPE_PARTNER, TYPE_SUPER_PARTNER, USER_SET_PARTNER} from "../../../reducers/admin/user";
import {useTranslation} from "react-i18next";


const SelectPartner = () => {

    let {user, selectedPartner} = useSelector(state => state.user)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const _renderButton = (item, type) => {
        if (type === TYPE_SUPER_PARTNER) {
            if (item.id !== selectedPartner?.id || selectedPartner.type !== TYPE_SUPER_PARTNER) {
                return <Button size={'sm'} text={t('basics.select')} rounded={true}
                               onClick={() => onSelectSuperPartner(item.id)}/>
            }
        } else if (type === TYPE_PARTNER) {
            if (item.id !== selectedPartner?.id || selectedPartner.type !== TYPE_PARTNER) {
                return <Button size={'sm'} text={t('basics.select')} rounded={true}
                               onClick={() => onSelectPartner(item.id)}/>
            }
        }

        return null
    }

    const onSelectSuperPartner = (id) => {
        let find = user.superPartners.find(item => item.id === id)
        dispatch({type: USER_SET_PARTNER, payload: {type: TYPE_SUPER_PARTNER, ...find}})
        localStorage.setItem("selectedPartner", id)
        localStorage.setItem("selectedPartnerType", TYPE_SUPER_PARTNER)
    }

    const onSelectPartner = (id) => {
        let find = user.partners.find(item => item.id === id)
        dispatch({type: USER_SET_PARTNER, payload: {type: TYPE_PARTNER, ...find}})
        localStorage.setItem("selectedPartner", id)
        localStorage.setItem("selectedPartnerType", TYPE_PARTNER)
    }


    const _renderContent = () => {
        let array = [];
        if (user.superPartners.length === 0 && user.partners.length === 0) return (
            <h4 className='text-center text-enigoo'>{t("partners.noPartners")}</h4>
        )
        if (user.superPartners.length) {
            array.push(<Widget title={t('partners.superPartner')}>

                <Table
                    data={user.superPartners}
                    columns={[
                        {Header: t('basics.name'), accessor: 'name'},
                        {Header: '', id: 'button', accessor: item => _renderButton(item, TYPE_SUPER_PARTNER)},
                    ]}
                />
            </Widget>)
        }

        if (user.partners.length) {
            array.push(<Widget title={t('partners.single')}>

                <Table
                    data={user.partners}
                    columns={[
                        {Header: t('basics.name'), accessor: 'name'},
                        {Header: '', id: 'button', accessor: item => _renderButton(item, TYPE_PARTNER)},
                    ]}
                />
            </Widget>)
        }

        return array;
    }

    return (
        <>
            <SectionTitle title={t('partners.changePartner')} subtitle={t('partners.listOf')}/>
            {_renderContent()}
        </>
    )
}

export default SelectPartner
