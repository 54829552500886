import React, {useEffect, useState} from 'react';
import FormModal from "../../../../components/Modal/FormModal";
import {apiSuperPartnerGetEventsToCopy, apiSuperPartnerPostEventsToCopy} from "../action";
import {useSelector} from "react-redux";
import SelectField from "../../../../components/FinalForm/SelectField/SelectField";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

const CopyEventModal = ({visible, onClose, eventId, subjectId, callback}) => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(false)

    const {t} = useTranslation()
    const {selectedPartner} = useSelector(state => state.user)

    useEffect(() => {
        apiSuperPartnerGetEventsToCopy(selectedPartner.id, eventId, subjectId, (data) => setData(data))
    }, [])

    const _renderContent = () => {
        return (
            <>
                <SelectField label={t('events.eventsToCopy')} name={'eventIds'} isMulti={true} loading={!data}
                             options={getOptions()}/>
            </>
        )
    }

    const getOptions = () => {
        if (!data) return []
        let array = [];
        data.forEach(item => {
            array.push({id: item.id, name: item.name})
        })

        return array;
    }

    const onSubmit = (values) => {
        setLoading(true)
        apiSuperPartnerPostEventsToCopy(selectedPartner.id, eventId, subjectId, values.eventIds, () => {
            callback()
            onClose()
            toast.success(t('events.copied'))
        })
    }

    return (
        <FormModal
            onSubmit={onSubmit}
            onClose={onClose}
            loading={loading}
            visible={visible}
            title={t('events.toCopy')}
            body={_renderContent()}
        />
    )


}

export default CopyEventModal;
