import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import {apiDeleteUserFromPartner, apiGetPartnersOfSuperPartners} from "../action";
import Button from "../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import FormModal from "../../../../components/Modal/FormModal";
import SelectField from "../../../../components/FinalForm/SelectField/SelectField";
import {toast} from "react-hot-toast";
import TooltipBadge from "../../../../components/TooltipBadge";
import Datatable from "../../../../components/core/Datatable/Datatable";
import Filters from "./Filters";
import {useTranslation} from "react-i18next";

const PartnerList = () => {

    let [partners, setPartners] = useState(null)
    let [removeUser, setRemoveUser] = useState(false)
    let [removingUser, setRemovingUser] = useState(false)

    const {selectedPartner} = useSelector(state => state.user)
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => getPartners(), [])

    const getPartners = (params = {}) => {
        apiGetPartnersOfSuperPartners(selectedPartner.id, (data) => setPartners(data), err => {}, params)
    }

    const getSubjects = (row) => {

        if (!row.subjects.length) return "-"
        let array = [];
        row.subjects.map((item, key) => (
            array.push(<span key={`${item.name}_${key}`}>{item.name} <br/></span>)
        ))

        return (
            <TooltipBadge id={`subject_${row.id}`} component={array} badgeText={t("partners.countOfSubjects",{count:array.length})}/>
        )
    }

    const getUsers = (row) => {
        if (!row.users.length) return "-"
        let array = [];
        row.users.map((item, key) => (
            array.push(<span key={item.username}>{item.username} <br/></span>)
        ))

        return (
            <TooltipBadge id={`users_${row.id}`} component={array} badgeText={t("partners.countOfUsers",{count:array.length})}/>
        )
    }


    const getButtons = (row) => {
        return (
            <>
                <Button size={'sm'} onClick={() => navigate({
                    pathname: 'connect',
                    search: `?id=${row.id}&name=${row.name}`
                })} rounded={true} text={t('users.assign')}/>
                {row.users.length ?
                    <Button className='ml-1' outline={true} size={'sm'} onClick={() => setRemoveUser(row)}
                            rounded={true}
                            text={t('users.remove')}/>
                    : null}
            </>
        )
    }

    const getType = (row) => {
        if (row.type === 1) return t('partners.physicalPerson')
        if (row.type === 2) return t('partners.company')
    }

    const onRemoveUser = (values) => {
        setRemovingUser(true)
        apiDeleteUserFromPartner(removeUser.id, values.user, () => {
            getPartners()
            toast.success(t('users.removed'))
            setRemoveUser(false)
            setRemovingUser(false)
        })
    }

    return (
        <>
            <SectionTitle title={t('partners.plural')} subtitle={t('partners.listOf')}/>
            <Filters onSearch={getPartners}/>
            <Widget>
                <ContentSpinner isSpinning={!partners}>
                    <>
                        <Datatable
                            disableSelection={true}
                            emptyText={t("partners.noPartnerExist")}
                            data={partners}
                            columns={[
                                {Header: t('partners.single'), accessor: 'name'},
                                {Header: t('basics.type'), id: 'type', accessor: row => getType(row)},
                                {Header: t('subjects.plural'), id: 'subject', accessor: row => getSubjects(row)},
                                {Header: t('users.plural'), id: 'user', accessor: row => getUsers(row)},
                                {Header: '', id: 'buttons', accessor: row => getButtons(row)},
                            ]}
                        />
                    </>
                </ContentSpinner>
                {removeUser &&
                    <FormModal loading={removingUser} onSubmit={onRemoveUser} onClose={() => setRemoveUser(false)}
                               visible={!!removeUser}
                               title={t('users.remove')} body={
                        <>
                            <SelectField label={t('users.select')} name={'user'}
                                         options={removeUser.users.map(item => {
                                             return {id: item.id, name: item.username}
                                         })}/>
                        </>
                    }/>
                }
            </Widget>
        </>
    )
}

export default PartnerList;
