import React, {useEffect, useState} from 'react'
import {useParams, useSearchParams,} from "react-router-dom";
import CenteredLayout from "../../../components/Layouts/CenteredLayout";
import {FiCheckCircle} from "react-icons/fi";
import Button from "../../../components/Button/Button";
import {FaFilePdf} from "react-icons/fa";
import {apiGetOrder, apiGetOrderPdf} from "../action";
import ContentSpinner from "../../../components/core/Spinner/ContentSpinner";
import FileSaver from 'file-saver'
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import SpartaFinalPage from "../Sparta/SpartaFinalPage";

const FinalPage = () => {

    let [loading, setLoading] = useState(true)
    let [data, setData] = useState(null)
    let [error, setError] = useState(null)
    let [loadingPdf, setLoadingPdf] = useState(false)

    const {orderId, channelId} = useParams()
    const [searchParams] = useSearchParams();
    const hash = searchParams.get('h')
    const oauth = searchParams.get('oauth')
    const {t} = useTranslation()

    useEffect(() => {
        apiGetOrder(channelId, orderId, hash, (data) => {
            setData(data)
            setLoading(false)
        }, err => {
            setError(err)
            setLoading(false)
        })
    }, [])


    const onPdfClick = () => {
        setLoadingPdf(true)
        apiGetOrderPdf(channelId, orderId, hash, (response) => {
            let name = new Date().getTime().toString()
            var blob = new Blob([response], {type: "application/pdf"});
            FileSaver.saveAs(blob, name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "_") + '.pdf')

            toast.success(t('tickets.downloaded'))
            setLoadingPdf(false)
        }, err => {
            setError(err)
            setLoadingPdf(false)
        })
    }

    const _renderContent = () => {
        if (loading) return null
        if (error) return <h6 className='text-center text-enigoo'>{t("orders.notFound")}</h6>;
        if (data) {
            if(oauth === 'true') return <SpartaFinalPage />
            return (
                <>
                    <h6 className='text-center text-enigoo'><FiCheckCircle size={20}
                                                                           className='inline-block align-middle mr-1'/>
                        {t("orders.wasSendToMail",{email:data.email})}</h6>
                    <div className='text-center pt-2'>
                        {t("orders.ifNotSend")}
                    </div>
                    <div className='text-center mt-3 mb-3'>
                        <Button onClick={onPdfClick} rounded={true} outline={false} loading={loadingPdf}
                                icon={<FaFilePdf/>} text={t("tickets.download")}/>
                    </div>
                </>
            )
        }
    }

    return (
        <CenteredLayout>
            <div className='lg:w-2/3 lg:m-0 bg-grey-50 border-grey-300 border rounded w-full m-3'>
                <div className='p-4'>
                    <ContentSpinner isSpinning={loading}>
                        {_renderContent()}
                    </ContentSpinner>
                </div>
            </div>
        </CenteredLayout>
    )
}


export default FinalPage;
