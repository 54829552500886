import React, {useState} from 'react'
import Widget from "../../../../components/core/Widget";
import Button from "../../../../components/Button/Button";
import {FiBox, FiSend, FiUsers} from "react-icons/fi";
import PartnerField from "../../../../components/FinalForm/ApiFields/PartnerField";
import FormModal from "../../../../components/Modal/FormModal";
import {useSelector} from "react-redux";
import {apiAddTicketsToPartner} from "../action";
import {useNavigate} from "react-router-dom";
import ChannelField from "../../../../components/FinalForm/ApiFields/ChannelField";
import {
    apiSuperPartnerRemoveFromChannel,
    apiSuperPartnerRemoveFromPartner,
    apiSuperPartnerTicketToChannel
} from "../../Channels/action";
import {switchError} from "../../../../api/api";
import {useTranslation} from "react-i18next";

const ButtonWidget = ({items, eventId, subjectId, callback}) => {

    let [addPartner, setAddPartner] = useState(false)
    let [removePartners, setRemovePartners] = useState(false)
    let [loadingPartner, setLoadingPartner] = useState(false)
    let [addChannel, setAddChannel] = useState(false)
    let [loadingChannel, setLoadingChannel] = useState(false)
    let [loadingRemoveChannel, setLoadingRemoveChannel] = useState(false)
    let [loadingRemovePartner, setLoadingRemovePartner] = useState(false)

    const {selectedPartner} = useSelector(state => state.user)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const getIds = () => items.map(item => item.id)
    const isSomeSold = () => items.find(item => item.isSold === true)

    const onAddPartner = (values) => {
        setLoadingPartner(true)
        apiAddTicketsToPartner(selectedPartner.id, eventId, subjectId, {
            partnerId: values.partnerId,
            ticketIds: getIds()
        }, () => {
            callback()
            setLoadingPartner(false)
            setAddPartner(false)
        }, err => {
            setLoadingPartner(false)
            setAddPartner(false)
            switchError(err, [], true)
        })
    }

    const onAddChannel = (values) => {
        setLoadingChannel(true)
        apiSuperPartnerTicketToChannel(selectedPartner.id, eventId, subjectId, {
            channelIds: values.channelIds,
            ticketIds: getIds()
        }, () => {
            callback()
            setLoadingChannel(false)
            setAddChannel(false)
        }, err => {
            setLoadingChannel(false)
            setAddChannel(false)
            switchError(err, [], true)
        })
    }

    const onRemoveChannel = () => {
        setLoadingRemoveChannel(true)
        apiSuperPartnerRemoveFromChannel(selectedPartner.id, {eventId, subjectId, ticketIds: getIds()}, () => {
            callback()
            setLoadingRemoveChannel(false)
        })
    }

    const onRemovePartners = (values) => {
        setLoadingRemovePartner(true)
        apiSuperPartnerRemoveFromPartner(selectedPartner.id, eventId, subjectId, {
            ticketIds: getIds(),
            partnerIds: values.partnerIds
        }, () => {
            callback()
            setLoadingRemovePartner(false)
            setRemovePartners(false)
        })
    }


    return (
        <Widget title={t('actions.plural')}>
            <div>
                <Button onClick={() => setAddPartner(true)} icon={<FiUsers/>} text={t("tickets.addToPartner")}
                        loading={loadingPartner} className='mr-1'
                        disabled={!items.length}/>
                <Button onClick={() => setAddChannel(true)} icon={<FiBox/>} text={t("tickets.addToDistributionChannel")}
                        loading={loadingChannel}
                        className='mr-1'
                        disabled={!items.length}/>
                <Button
                    onClick={() => navigate(`/admin/super-partner/events/order?eventId=${eventId}&subjectId=${subjectId}&ticketIds=${getIds().join(',')}`)}
                    icon={<FiSend/>} text={t("tickets.pickupTickets")} className='mr-1'
                    disabled={!items.length || isSomeSold()}/>
            </div>
            <div className='mt-2'>
                <Button onClick={() => setRemovePartners(true)} outline={true} icon={<FiUsers/>}
                        text={t("tickets.removeFromPartners")}
                        loading={loadingRemovePartner} className='mr-1'
                        disabled={!items.length}/>

                <Button onClick={onRemoveChannel} outline={true} icon={<FiBox/>}
                        text={t("tickets.removeFromDistributionChannel")}
                        loading={loadingRemoveChannel} className='mr-1'
                        disabled={!items.length}/>
            </div>

            <FormModal onSubmit={onAddPartner} visible={addPartner} title={t("tickets.addToPartner")}
                       loading={loadingPartner}
                       body={<>
                           <PartnerField name={'partnerId'} superPartnerId={selectedPartner.id} label={'Partner'}
                                         validate={true}
                                         isMulti={false}/>
                       </>}
                       onClose={() => setAddPartner(false)}/>
            <FormModal onSubmit={onRemovePartners} visible={removePartners} title={t("tickets.removeFromPartners")}
                       loading={loadingPartner}
                       body={<>
                           <PartnerField name={'partnerIds'} superPartnerId={selectedPartner.id} label={t('partners.single')}
                                         isMulti={true}
                                         validate={true}
                           />
                       </>}
                       onClose={() => setRemovePartners(false)}/>
            <FormModal onSubmit={onAddChannel} visible={addChannel} title={t('tickets.addToDistributionChannel')}
                       loading={loadingChannel}
                       body={<>
                           <ChannelField name={'channelIds'} superPartnerId={selectedPartner.id}
                                         label={t('distributionChannels.single')}
                                         validate={true}
                                         isMulti={true}/>
                       </>}
                       onClose={() => setAddChannel(false)}/>
        </Widget>
    )
}

export default ButtonWidget;
