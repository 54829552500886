import React from 'react'
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const TypeRoute = ({component, type}) => {

    const {selectedPartner} = useSelector(state => state.user)

    if (selectedPartner.type === type) return component;

    return (
        <div className="flex flex-col w-full max-w-xl text-center m-auto mt-4">
            <h1 className="text-6xl text-blue-500 mb-4">404</h1>

            <div className="mb-8 text-center text-grey-500">
                Požadována stránka nebyla nalezena!
            </div>
            <div className="flex w-full">
                <Link
                    to="/admin"
                    className="btn btn-lg btn-rounded btn-block bg-blue-500 hover:bg-blue-600 text-white">
                    Go back
                </Link>
            </div>
        </div>
    )

}

export default TypeRoute
