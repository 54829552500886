import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import cs from "./locale/cs.json"
import sk from "./locale/sk.json"
import en from "./locale/en.json"
import de from "./locale/de.json"

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: 'cs',
        supportedLngs:["cs","sk","en","de"],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {},
        react:{
            useSuspense:false
        },
        detection: {
            order: ['localStorage'],
            caches: ['localStorage'],
        },
    });
i18n.addResourceBundle("cs","translation",cs)
i18n.addResourceBundle("en","translation",en)
i18n.addResourceBundle("de","translation",de)
i18n.addResourceBundle("sk","translation",sk)

export default i18n;
