import React, {useEffect, useState} from "react";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import { apiGetUsers, apiUserManualVerify} from "../action";
import Button from "../../../../components/Button/Button";
import {HiPlusCircle} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import Datatable from "../../../../components/core/Datatable/Datatable";
import TooltipBadge from "../../../../components/TooltipBadge";
import Filters from "./Filters";
import {FiCheckSquare} from 'react-icons/fi'
import {switchError} from "../../../../api/api";
import {useTranslation} from "react-i18next";

const UserList = () => {

    let [users, setUsers] = useState(false)
    let [verify, setVerified] = useState(null)
    let [stateParams, setParams] = useState(null)

    const {t} = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = (params = {}) => {
        setParams(params)
        apiGetUsers(data => {
            setUsers(data)
        }, err => {
        }, params)
    }

    const manualVerify = (userId) => {
        setVerified(userId)
        apiUserManualVerify({userId}, () => {
            getUsers(stateParams)
            setVerified(null)
        }, err => {
            switchError(err, [{400: [{type: 'VERIFIED', message: t('verifyUser.verified')}]}])
        })
    }

    const getSubjects = (items, id) => {

        if (!items.length) return "-"
        let array = [];
        items.map((item, key) => (
            array.push(<span key={`${item.name}_${key}`}>{item.name} <br/></span>)
        ))

        return (
            <TooltipBadge id={`subject_${id}`} component={array} badgeText={t("subjects.countOfSubject",{count:items.length})}/>
        )
    }

    const _renderVerifyButton = (row) => {
        if (row.isVerify) return <span className={"uppercase"}>{t("basics.yes")}</span>

        return <span><Button size={'sm'} loading={row.id === verify} icon={<FiCheckSquare/>}
                             onClick={() => manualVerify(row.id)}/></span>
    }

    return (
        <>
            <SectionTitle title={t('basics.system')} subtitle={t('users.plural')}/>
            <Filters onSearch={getUsers}/>
            <Widget>
                <ContentSpinner isSpinning={!users}>
                    <div className='text-right'>
                        <Button onClick={() => navigate('super-partner')} rounded={true}
                                text={t('users.assignToSuperPartner')} size={"default"} icon={<HiPlusCircle size={17}/>}/>
                    </div>
                    <Datatable
                        disableSelection={true}
                        data={users}
                        columns={[
                            {Header: t("users.username"), accessor: 'username'},
                            {Header: t('users.emailVerified'), accessor: d => _renderVerifyButton(d)},
                            {Header: t('partners.superPartner'), accessor: d => getSubjects(d.superPartners, d.id)},
                        ]}
                    />
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default UserList
