import React from "react";
import {FormSpy, Form} from "react-final-form";
import TextField from "../../../components/FinalForm/TextField/TextField";
import {useDispatch, useSelector} from "react-redux";
import {CHANNEL_SET_TICKET_COUNT} from "../../../reducers/channel/channel";
import {useTranslation} from "react-i18next";

const TicketCount = ({count}) => {

    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {isSubmitted, type} = useSelector(state => state.channel)

    return (
        <Form keepDirtyOnReinitialize onSubmit={values => console.log(values)} render={({handleSubmit}) => (
            <>
                <TextField label={t("tickets.enterCountOfTickets",{count:count})} inputType={'number'}
                           name={'ticketCount'} instantRenderError={isSubmitted && type === 0}
                           renderErrorWithoutSubmit={true}
                           validate={value => {
                               if (!value) return true;
                               if (parseInt(value) > count) return t("tickets.countMustBeSmallerOrEqual")
                               if (parseInt(value) > 100) return t("tickets.max100tickets")
                               return false
                           }}/>
                <FormSpy subscription={{values: true}} onChange={(values, valid) => {
                    dispatch({type: CHANNEL_SET_TICKET_COUNT, payload: values.values.ticketCount})
                }}/>
            </>
        )}/>
    )
}

export default TicketCount
