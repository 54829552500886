import axios from "axios";
import {toast} from "react-hot-toast";

export const api = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "http://partner.local/" : "/"
});


export const destroyref = (object) => {
    return JSON.parse(JSON.stringify(object))
}


export const apiErrorParser = (errorResponse) => {
    return {
        status: errorResponse?.status,
        type: errorResponse?.data?.error?.type,
        message: errorResponse?.data?.error?.message,
        data: errorResponse?.data?.error?.data
    }
}


export const switchError = (error, data = [], showDefault = false) => {

    if (showDefault) {
        if (error.status === 500) {
            toast.error('Nastala neočekávaná chyba')
        } else {
            toast.error(error.message)
        }

        return;
    }
    switch (error.status) {
        case 400:
            if (data[400]) {
                data[400].map(item => {
                    if (error.type === item.type) {
                        if (item.message) {
                            toast.error(item.message)
                        } else {
                            toast.error(error.message)
                        }
                    }
                })
            }
            break;
        default:
            toast.error('Nastala neočekávaná chyba')
            break;
    }
}
