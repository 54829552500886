import {api, apiErrorParser} from "../../../api/api";
import {USER_SET_TOKEN} from "../../../reducers/admin/user";

export const apiLogin = (values, success, error) => {
    api.post('api/v1/user', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const axiosSetAuthorizationToken = (token, dispatch) => {
    api.defaults.headers.common["Authorization"] = token;
    localStorage.setItem("Authorization", token)
    dispatch({type: USER_SET_TOKEN, payload: token})
}


export const getTokenFromLocalStorage = (dispatch) => {
    let token = localStorage.getItem('Authorization')
    if (token === null) return false;
    axiosSetAuthorizationToken(token, dispatch)
}

export const apiGetUserVerify = (verifyString, success, error) => {
    api.get('api/v1/user/verify/' + verifyString)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiUserVerify = (verifyString, values, success, error) => {
    api.post('api/v1/user/verify/' + verifyString, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiResetPassword = (values, success, error) => {
    api.post('api/v1/user/password-reset', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiGetResetPassword = (email, hash, success, error) => {
    api.get('api/v1/user/password-reset', {params: {email, hash}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiChangePassword = (email, hash, password, success, error) => {
    api.put('api/v1/user/password-reset', {password}, {params: {email, hash}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiResendVerificationEmail = (values, success, error) => {
    api.post('api/v1/user/verify-resend', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

