import React, {useEffect, useState} from 'react';
import SelectField from "../SelectField/SelectField";
import {useSelector} from "react-redux";
import {apiPartnerGetChannels, apiSuperPartnerGetChannels} from "../../../pages/Admin/Channels/action";
import {TYPE_PARTNER, TYPE_SUPER_PARTNER} from "../../../reducers/admin/user";


const ChannelField = ({superPartnerId, ...props}) => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(true)
    const {selectedPartner} = useSelector(state => state.user)

    useEffect(() => {
        if (selectedPartner.type === TYPE_SUPER_PARTNER) {
            apiSuperPartnerGetChannels(selectedPartner.id, (data) => {
                setData(data)
                setLoading(false)
            })
        } else if (selectedPartner.type === TYPE_PARTNER) {
            apiPartnerGetChannels(selectedPartner.id, (data) => {
                setData(data)
                setLoading(false)
            })
        }
    }, [])

    return (
        <SelectField  {...props} options={data} loading={loading}/>
    )
}

export default ChannelField;
