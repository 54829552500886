import React, {useEffect, useState} from "react";
import Widget from "../../../components/core/Widget";
import UnderlinedTabs from "../../../components/core/UnderLineTabs";
import TicketCount from "./TicketCount";
import TicketTable from "./TicketTable";
import {useDispatch} from "react-redux";
import {CHANNEL_SET_TYPE} from "../../../reducers/channel/channel";
import {useTranslation} from "react-i18next";


const Tickets = ({tickets, withSelect}) => {

    let [tabs, setTabs] = useState([])

    const dispatch = useDispatch()
    const {t,i18n} = useTranslation()

    useEffect(() => {
        let array = [];

        array.push({
            index: 0,
            content: <TicketCount count={tickets.length}/>,
            title: t('tickets.countOf')
        })

        if (withSelect) {
            array.push({
                index: 1,
                content: <TicketTable tickets={tickets}/>,
                title: t('tickets.specifics')
            })
        }

        setTabs(array);

    }, [i18n.language])

    return (
        <Widget title={t('tickets.plural')}>
            <UnderlinedTabs tabs={tabs} callback={(type) => dispatch({type: CHANNEL_SET_TYPE, payload: type})}/>
        </Widget>
    )
}

export default Tickets
