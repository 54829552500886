export const CHANNEL_SET_TYPE = "CHANNEL_SET_TYPE";
export const CHANNEL_SET_TICKET_COUNT = "CHANNEL_SET_TICKET_COUNT";
export const CHANNEL_SET_SELECTED_TICKETS = "CHANNEL_SET_SELECTED_TICKETS";
export const CHANNEL_SET_SUBMITTED = "CHANNEL_SET_SUBMITTED";
export const CHANNEL_SET_USER = "CHANNEL_SET_USER";
export const CHANNEL_SET_OAUTH = "CHANNEL_SET_OAUTH";


export default function reducer(
    state = {
        type: 0,
        ticketCount: 0,
        selectedTickets: [],
        isSubmitted: false,
        isValid: false,
        user: null,
        isOauth: false
    },
    action
) {
    switch (action.type) {
        case CHANNEL_SET_TYPE:
            return {
                ...state,
                type: action.payload,
                isValid: validation({...state, type: action.payload})
            }
        case CHANNEL_SET_TICKET_COUNT:
            return {
                ...state,
                ticketCount: parseInt(action.payload),
                isValid: validation({...state, ticketCount: parseInt(action.payload)})
            }
        case CHANNEL_SET_SELECTED_TICKETS:
            return {
                ...state,
                selectedTickets: action.payload,
                isValid: validation({...state, selectedTickets: action.payload})
            }
        case CHANNEL_SET_SUBMITTED:
            return {
                ...state,
                isSubmitted: true,
                isValid: validation(state, action.payload)
            }
        case CHANNEL_SET_USER:
            return {
                ...state,
                user: action.payload,
                isValid: validation({...state, user: {...action.payload}})
            }
        case CHANNEL_SET_OAUTH:
            return {
                ...state,
                isOauth: action.payload
            }

    }

    return state
}


const validation = (state) => {
    let isValid = true
    if (state.type === 0 && !state.ticketCount) {
        isValid = false
    } else if (state.type === 1 && state.selectedTickets.length === 0) {
        isValid = false
    }

    if (state.isOauth) {
        isValid = true;
    }

    if (!state.isOauth) {
        if (!state.user) {
            isValid = false;
        } else if (!state.user.isValid) {
            isValid = false;
        }

    }

    if (state.ticketCount > 100) {
        isValid = false
    }


    return isValid;
}
