import React from 'react'
import PropTypes from "prop-types"

const CenteredForm = ({title, subtitle, children}) => {
    return (
        <div className="flex flex-col bg-white border border-grey-200 p-8 w-full max-w-xl">
            <div className="flex flex-col w-full mb-4">
                <div className="text-xs uppercase">{title}</div>
                <div className="text-lg font-bold">{subtitle}</div>
            </div>
            {children}
        </div>
    )
}

CenteredForm.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
}

export default CenteredForm
