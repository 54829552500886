import React from "react";
import {FiAlertCircle} from "react-icons/fi";
import {useTranslation} from "react-i18next";


const Error = ({error}) => {

    if (!error) return null;
    switch (error.status) {
        case 400:
            switch (error.type) {
                case 'CHANNEL_NOT_FOUND':
                    return <NotFound/>
                default:
                    return <NotFound/>
            }
        default:
            return <NotFound/>
    }
}


const NotFound = () => {
    const {t} = useTranslation()
    return(
        <div className='text-center flex flex-col'>
            <div className='flex justify-center'>
                <FiAlertCircle size={30} className='text-enigoo' />
            </div>
            <h4 className='text-enigoo font-bold'>{t("basics.pageNotFound")}</h4>
        </div>
    )
}

export default Error;
