import React from "react"
import {Routes, Route} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import AuthorizedRoute from "../../components/Layouts/AuthorizedRoute";
import SubjectList from "./Subjects/List/SubjectList";
import CreateSubject from "./Subjects/Create/CreateSubject";
import UserCreate from "./Users/Create/UserCreate";
import UserList from "./Users/List/UserList";
import CreateSuperPartner from "./SuperPartners/Create/CreateSuperPartner";
import SuperPartnerList from "./SuperPartners/List/SuperPartnerList";
import SuperPartner from "./Users/SuperPartner/SuperPartner";
import SelectPartner from "./SelectPartner/SelectPartner";
import SuperPartnerEvents from "./Events/Events";
import PartnerList from "./Partners/PartnerList/PartnerList";
import PartnerCreate from "./Partners/Create/PartnerCreate";
import PartnerToUser from "./Partners/Connect/PartnerToUser";
import TicketList from "./Events/TicketList/TicketList";
import CreateOrder from "./Order/SuperPartner/CreateOrder";
import OrderList from "./Order/SuperPartner/OrderList/OrderList";
import OrderDetail from "./Order/SuperPartner/Detail/OrderDetail";
import ChannelList from "./Channels/SuperPartner/ChannelList";
import TypeRoute from "../../components/Layouts/TypeRoute";
import {TYPE_PARTNER, TYPE_SUPER_PARTNER} from "../../reducers/admin/user";
import PartnerTicketList from "./Events/PartnerTicketList/PartnerTicketList";
import PartnerCreateOrder from "./Order/Partner/PartnerCreateOrder";
import PartnerOrderList from "./Order/Partner/OrderList/PartnerOrderList";
import PartnerOrderDetail from "./Order/Partner/PartnerOrderDetail";
import SuperPartnerSummary from "./Summary/SuperPartner/SuperPartnerSummary";


const Routing = () => {

    return (
        <Routes>
            <Route path={'dashboard'} element={<Dashboard/>}/>
            <Route path={'switch-partner'} element={<SelectPartner/>}/>
            <Route path={'super-partner/channels'}
                   element={<TypeRoute component={<ChannelList/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/events'}
                   element={<TypeRoute component={<SuperPartnerEvents/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'partner/events'}
                   element={<TypeRoute component={<SuperPartnerEvents/>} type={TYPE_PARTNER}/>}/>
            <Route path={'partner/events/:eventId/tickets'}
                   element={<TypeRoute component={<PartnerTicketList/>} type={TYPE_PARTNER}/>}/>
            <Route path={'partner/events/:eventId/order'}
                   element={<TypeRoute component={<PartnerCreateOrder/>} type={TYPE_PARTNER}/>}/>
            <Route path={'partner/orders'}
                   element={<TypeRoute component={<PartnerOrderList/>} type={TYPE_PARTNER}/>}/>
            <Route path={'partner/orders/:orderId'}
                   element={<TypeRoute component={<PartnerOrderDetail/>} type={TYPE_PARTNER}/>}/>
            <Route path={'partner/channels'}
                   element={<TypeRoute component={<ChannelList/>} type={TYPE_PARTNER}/>}/>
            <Route path={'super-partner/orders'}
                   element={<TypeRoute component={<OrderList/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/orders/:orderId'}
                   element={<TypeRoute component={<OrderDetail/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/events/tickets'}
                   element={<TypeRoute component={<TicketList/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/events/order'}
                   element={<TypeRoute component={<CreateOrder/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/partners'}
                   element={<TypeRoute component={<PartnerList/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/partners/add'}
                   element={<TypeRoute component={<PartnerCreate/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/partners/connect'}
                   element={<TypeRoute component={<PartnerToUser/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'super-partner/summary'}
                   element={<TypeRoute component={<SuperPartnerSummary/>} type={TYPE_SUPER_PARTNER}/>}/>
            <Route path={'system/users'} element={<AuthorizedRoute component={<UserList/>}/>}/>
            <Route path={'system/users/add'} element={<AuthorizedRoute component={<UserCreate/>}/>}/>
            <Route path={'system/users/super-partner'} element={<AuthorizedRoute component={<SuperPartner/>}/>}/>
            <Route path={'system/subjects'} element={<AuthorizedRoute component={<SubjectList/>}/>}/>
            <Route path={'system/subjects/add'} element={<AuthorizedRoute component={<CreateSubject/>}/>}/>
            <Route path={'system/super-partners'} element={<AuthorizedRoute component={<SuperPartnerList/>}/>}/>
            <Route path={'system/super-partners/add'} element={<AuthorizedRoute component={<CreateSuperPartner/>}/>}/>


            <Route exact path={'/'} element={<Dashboard/>}/>
        </Routes>
    )
}

export default Routing;
