import React, {useEffect, useRef} from 'react'
import Portal from './Portal'
import {FiX} from 'react-icons/fi'
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";

const Modal = ({title, body, visible, onClose, onSave, loading}) => {

    const modalRef = useRef(null)
    const {t} = useTranslation();
    return (
        <>
            {visible && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show`} data-background={'light'}>
                        <div className="relative min-w-sm w-11/12 lg:w-1/3 md:w-1/2  mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{title}</h3>
                                </div>
                                <div className="relative p-4 flex-auto text-sm">{body}</div>
                                <div className="modal-footer space-x-2">
                                    <Button onClick={onClose} rounded={true} outline={true} text={t('basics.close')} />
                                    <Button onClick={onSave} rounded={true} text={t('basics.save')} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}

export default Modal
