import React from "react";
import Spinner from "./Spinner";
import PropTypes from "prop-types";

const ContentSpinner = ({isSpinning, children}) => {

    return (
        <>
            {isSpinning ?
                <div className='flex justify-center content-center'>
                    <Spinner size={5} color={'black'}/>
                </div>
                :
                children
            }
        </>


    )
}

ContentSpinner.propTypes = {
    isSpinning: PropTypes.bool,
}

export default ContentSpinner
