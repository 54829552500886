import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {apiAuth} from "./action";
import {
    TYPE_PARTNER,
    TYPE_SUPER_PARTNER,
    USER_INIT,
    USER_SET_PARTNER,
    USER_SET_TOKEN
} from "../../../reducers/admin/user";
import Loader from "../../../components/core/Loader/Loader";

const Auth = () => {

    let [done, setDone] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        apiAuth(data => {
            getSelected(data)
            dispatch({type: USER_INIT, payload: data})
            setDone(true)
        }, () => {
            localStorage.removeItem('Authorization')
            dispatch({type: USER_SET_TOKEN, payload: null})
            setDone(true)
        })
    }, [])


    const getSelected = (data) => {
        if ((data.superPartners.length + data.partners.length) < 2) {
            if (data.superPartners.length) {
                dispatch({type: USER_SET_PARTNER, payload: {type: TYPE_SUPER_PARTNER, ...data.superPartners[0]}})
            } else if (data.partners.length) {
                dispatch({type: USER_SET_PARTNER, payload: {type: TYPE_PARTNER, ...data.partners[0]}})
            }
        } else {
            let selectedPartner = localStorage.getItem('selectedPartner')
            let selectedPartnerType = localStorage.getItem('selectedPartnerType')
            if (selectedPartner && selectedPartnerType) {
                let findIndex = -1;
                let type = null;
                if (selectedPartnerType === TYPE_SUPER_PARTNER) {
                    findIndex = data.superPartners.findIndex(item => item.id === parseInt(selectedPartner))
                    type = TYPE_SUPER_PARTNER;
                } else if (selectedPartnerType === TYPE_PARTNER) {
                    findIndex = data.superPartners.findIndex(item => item.id === parseInt(selectedPartner))
                    type = TYPE_PARTNER
                }
                if (findIndex !== -1) {
                    dispatch({
                        type: USER_SET_PARTNER,
                        payload: {type: type, ...data.superPartners[findIndex]}
                    })
                }
            }
        }
    }


    return <Loader isDone={done}/>
}

export default Auth;
