import {api, apiErrorParser} from "../../api/api";

export const apiChannelGetEvents = (channelUrl, success, error) => {
    api.get(`api/v1/channel/${channelUrl}`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiChannelGetEvent = (channelUrl, eventId, success, error) => {
    api.get(`api/v1/channel/${channelUrl}/${eventId}`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiCreateOrder = (channelUrl, eventId, values, type, success, error) => {
    api.post(`api/v1/channel/${channelUrl}/${eventId}`, values, {params: {type: type}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetOrder = (channelUrl, orderId, hash, success, error) => {
    api.get(`api/v1/channel/${channelUrl}/order/${orderId}`, {params: {hash}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetOrderPdf = (channelUrl, orderId, hash, success, error) => {
    api.get(`api/v1/channel/${channelUrl}/order/${orderId}/pdf`, {params: {hash}, responseType: 'arraybuffer'})
        .then(response => success(response.data))
        .catch(err => error(apiErrorParser(err.response)))

}

export const apiGetChannelOauth = (channelUrl, url, success, error) => {
    api.get(`api/v1/channel/${channelUrl}/oauth`, {params: {url: `/${url}`}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiPostChannelOauthLogout = (channelUrl, values, success, error) => {
    api.post(`api/v1/channel/${channelUrl}/oauth-logout`, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}
