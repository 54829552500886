import React, {useEffect, useState} from 'react'
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import OrderForm from "./OrderForm";
import {useNavigate, useSearchParams} from "react-router-dom";
import {apiGetSpecificTickets, apiSuperPartnerOrder, apiSuperPartnerOrderNoCustomer} from "../action";
import {useSelector} from "react-redux";
import Table from "../../../../components/Table/Table";
import {toast} from "react-hot-toast";
import {switchError} from "../../../../api/api";
import FileSaver from 'file-saver'
import Alert from "../../../../components/core/Alert";
import {FiInfo} from "react-icons/fi";
import {useTranslation} from "react-i18next";


const CreateOrder = () => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(false)
    let [initialValues, setInitialValues] = useState({})

    const {selectedPartner} = useSelector(state => state.user)
    const navigate = useNavigate()

    const {t} = useTranslation()

    const [searchParams] = useSearchParams();
    const eventId = searchParams.get('eventId')
    const subjectId = searchParams.get('subjectId')
    const ticketIds = searchParams.get('ticketIds')

    useEffect(() => {
        getCustomer()
        if (eventId && subjectId && ticketIds) {
            apiGetSpecificTickets(selectedPartner.id, eventId, subjectId, {ticketIds: ticketIds.split(',').map(Number)}, (data) => setData(data))
        }
    }, [])

    const getCustomer = () => {
        let item = localStorage.getItem('saveCustomer')
        if (item) {
            setInitialValues({
                saveCustomer: true,
                ...JSON.parse(item),
            })
        }
    }

    const saveCustomer = (values) => {
        if (values.saveCustomer) {
            localStorage.setItem('saveCustomer', JSON.stringify({
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
                telephone: values.telephone ? values.telephone : null,
            }))
        } else {
            localStorage.removeItem('saveCustomer')
        }
    }

    const getConfig = (values) => {
        if (ticketIds.split(',').map(Number) > 100) return {}
        if (values.isEmail) return {}
        return {responseType: 'arraybuffer'}

    }

    const onNoPdfSubmit = (values) => {
        apiSuperPartnerOrderNoCustomer(selectedPartner.id, eventId, subjectId, {
            ticketIds: ticketIds.split(',').map(Number),
        }, (data) => {
            toast.success(t("tickets.ticketsWasPickup"))

            navigate(`/admin/super-partner/events/tickets?eventId=${eventId}&subjectId=${subjectId}`)
        })
    }

    const onOrderSubmit = (values) => {
        if (data?.noPdf) {
            onNoPdfSubmit(values)
        } else {
            onClassicOrderSubmit(values)
        }
    }

    const onClassicOrderSubmit = (values) => {
        setLoading(true)
        apiSuperPartnerOrder(selectedPartner.id, eventId, subjectId, {
            ticketIds: ticketIds.split(',').map(Number),
            customer: {
                email: values.email,
                firstname: values.firstname ? values.firstname : null,
                lastname: values.lastname ? values.lastname : null,
                telephone: values.telephone ? values.telephone : null,
            },
            singlePdf: values.singlePdf,
            isEmail: values.isEmail,
            isZip: data.tickets.length > 100 ? true : !!values.isZip,
            note: values.note
        }, (response) => {
            saveCustomer(values)
            if (response.data?.orderId) {
                toast.success(t("orders.created"))
                navigate(`/admin/super-partner/orders/${response.data.orderId}`)
            } else if (values.isEmail && response.data.status) {
                toast.success(t("tickest.ticketsWasSend", {email: values.email}))
                navigate(`/admin/super-partner/events/tickets?eventId=${eventId}&subjectId=${subjectId}`)

            } else if (values.isEmail && !response.data.status) {
                toast.error(t("orders.createdWithoutEmail"), {duration: 6000})
            } else if (values.isZip) {
                let name = new Date().getTime().toString()
                var blob = new Blob([response], {type: "application/zip"});
                FileSaver.saveAs(blob, name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "_") + '.zip')

                toast.success(t("tickets.ticketsWasPickup"))

                navigate(`/admin/super-partner/events/tickets?eventId=${eventId}&subjectId=${subjectId}`)
            } else {
                let name = new Date().getTime().toString()
                var blob = new Blob([response], {type: "application/pdf"});
                FileSaver.saveAs(blob, name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "_") + '.pdf')

                toast.success(t("tickets.ticketsWasPickup"))

                navigate(`/admin/super-partner/events/tickets?eventId=${eventId}&subjectId=${subjectId}`)
            }

        }, err => {
            setLoading(false)
            switchError(err, [{400: [{type: 'TICKET_ERROR'}]}], true)
        }, getConfig(values))
    }

    return (
        <>
            <SectionTitle title={t('tickets.plural')} subtitle={t('tickets.pickupTickets')}/>
            <Widget title={t("basics.form")}>
                {data && data.tickets.length > 100 &&
                    <Alert size={'sm'} raised={true} borderLeft={true} withoutClose={true}
                           color={'border-purple-900 text-xs mb-4'}
                           children={<div><FiInfo style={{display: 'inline-block'}}/> <span
                               style={{verticalAlign: 'middle'}}>{t("orders.moreThan100Tickets")}</span>
                           </div>}/>
                }
                {data &&
                <OrderForm initialValues={initialValues} onSubmit={onOrderSubmit} loading={loading} noPdf={data?.noPdf}
                           ticketCount={data ? data.tickets.length : 0}/>
                }
            </Widget>
            <Widget title={t('tickets.selectedTickets') + (data ? ` (${data.tickets.length})` : '')}>
                <ContentSpinner isSpinning={!data}>
                    <Table data={data ? data.tickets : []} columns={[
                        {
                            Header: t('tickets.enigooId'),
                            accessor: 'id'
                        },
                        {
                            Header: t('tickets.sector'),
                            accessor: 'sector'
                        },
                        {
                            Header: t('tickets.row'),
                            accessor: 'row'
                        },
                        {
                            Header: t('tickets.place'),
                            accessor: 'place'
                        },]}
                    />
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default CreateOrder;
