import React, {useEffect, useState} from "react";
import SectionTitle from "../../../../../components/core/SectionTitle";
import Widget from "../../../../../components/core/Widget";
import ContentSpinner from "../../../../../components/core/Spinner/ContentSpinner";
import Datatable from "../../../../../components/core/Datatable/Datatable";
import {apiGetPartnerOrders} from "../../action";
import {useSelector} from "react-redux";
import DateClassic from "../../../../../components/Date/DateClassic";
import Button from "../../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import Filters from "./Filters";
import {FiClipboard} from "react-icons/fi";
import TooltipBadge from "../../../../../components/TooltipBadge";
import {useTranslation} from "react-i18next";


const PartnerOrderList = () => {

    let [tickets, setTickets] = useState(null)
    let [events, setEvents] = useState([])
    let [filtered, setFiltered] = useState(null)

    const {selectedPartner} = useSelector(state => state.user)
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => apiGetPartnerOrders(selectedPartner.id, (data) => {
        setTickets(data.purchases)
        setEvents(data.events)
    }), [])

    const _renderTable = () => {
        if (!tickets) return null;

        return (
            <>
                <Datatable emptyText={t('orders.noOrders')} data={filtered ? filtered : tickets} disableSelection={true}
                           columns={[
                               {
                                   Header: '#ID',
                                   id: 'id',
                                   accessor: d => <span>
                                       <span>{d.id} {d.isStorno ? <sup className='text-red-900 uppercase'>{t("orders.cancelled")}</sup> : ""}</span>
                                       {d.note &&
                                           <div className='inline-block'>
                                               <TooltipBadge id={`purchase_${d.id}`} badgeText={<FiClipboard />} color={'white'} position={'right'}
                                                             component={<span>{d.note}</span>}/>
                                           </div>
                                       }

                                   </span>
                               },
                               {
                                   Header: t('subjects.single'),
                                   accessor: 'subject'
                               },
                               {
                                   Header: t('basics.date'),
                                   id: 'date',
                                   accessor: d => <DateClassic dateIso={d.date} format={'LLL'}/>
                               },
                               {
                                   Header: t('events.single'),
                                   id: 'event',
                                   accessor: d => d.eventName ?
                                       <span>{d.eventName} <sup><DateClassic dateIso={d.eventDate}
                                                                             format={'LLL'}/></sup></span> : ' -'
                               },
                               {
                                   Header: t('customers.single'),
                                   id: 'customer',
                                   accessor: d => <span>{d.customer ? d.customer : "-"}</span>
                               },
                               {
                                   Header: t('orders.pickupByUser'),
                                   accessor: 'user'
                               },
                               {
                                   Header: t('tickets.count'),
                                   accessor: 'ticketCount'
                               },
                               {
                                   Header: '',
                                   id: 'button',
                                   accessor: d => <div>
                                       <Button
                                           className='inline-block'
                                           onClick={() => navigate(`/admin/partner/orders/${d.id}`)}
                                           rounded={true} text={t('basics.detail')}/>
                                   </div>
                               },
                           ]}/>
            </>

        )
    }

    const onSearch = (values) => {
        if (Object.keys(values).length === 0) {
            setFiltered(null)
            return;
        }
        let filter = tickets.filter(item => {
            return (values?.id ? (parseInt(values.id) === item.id) : true) &&
                (values?.customer ? (item.customer ? item.customer.includes(values.customer) : false) : true)
        })

        setFiltered([...filter])
    }

    return (
        <>
            <SectionTitle title={t('orders.plural')} subtitle={t('tickets.pickupedTickets')}/>
            <Filters onSearch={onSearch} events={events}/>
            <Widget title={t('basics.data')}>
                <ContentSpinner isSpinning={!tickets}>
                    {_renderTable()}
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default PartnerOrderList
