import React, {useState} from 'react';
import Button from "./Button";
import {apiOauthUser} from "../../pages/Admin/Users/action";


const SpartaButton = () => {

    let [loading, setLoading] = useState()

    const onOauthClick = () => {
        setLoading(true)
        apiOauthUser(window.location.pathname + window.location.search, (data) => {
            window.location.href = data.url
        })
    }

    return (
        <Button text={'Přihlásit do SpartaID'} onClick={onOauthClick} loading={loading}/>
    )
}

export default SpartaButton;
