import React, {useEffect} from 'react'
import NProgress from 'nprogress'
import './styles.css'

const Loader = ({isDone}) => {

    useEffect(() => NProgress.start(), [])

    useEffect(() => {
        NProgress.done()
    }, [isDone])

    return <div/>
}

export default Loader
