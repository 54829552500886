import {api, apiErrorParser} from "../../../api/api";

export const apiGetPartnersOfSuperPartners = (superPartnerId, success, error, params = {}) => {
    api.get(`api/v1/partner/super-partner/${superPartnerId}`, {params: params})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiGetSuperPartnersSubjects = (superPartnerId, success, error) => {
    api.get('api/v1/super-partner/' + superPartnerId + '/subject')
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiCreatePartner = (superPartnerId, values, success, error) => {
    api.post('api/v1/partner/super-partner/' + superPartnerId, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiConnectPartnerToUser = (partnerId, values, success, error) => {
    api.post('api/v1/partner/' + partnerId + "/user", values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiDeleteUserFromPartner = (partnerId, userId, success, error) => {
    api.delete(`/api/v1/partner/${partnerId}/super-partner/user/${userId}`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiGetIcoCompany = (ico, success, error) => {
    api.get('api/v1/ares', {params: {ico}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}
