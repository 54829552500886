import {api, apiErrorParser} from "../../../api/api";

export const apiCreateUser = (values, success, error) => {
    api.post('api/v1/user/users', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiOauthUser = (url, success, error) => {
    api.get('api/v1/user/oauth', {params: {url: url}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiOauthUserLogout = (success, error) => {
    api.post('api/v1/user/oauth/logout')
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}




export const apiGetUsers = (success, error, params = {}) => {
    api.get('api/v1/user/users', {params: params})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiUserManualVerify = (values, success, error) => {
    api.post('api/v1/user/users/manual-verify', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiUsersToSuperPartner = (superPartnerId, values, success, error) => {
    api.post('api/v1/super-partner/' + superPartnerId + '/user', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

