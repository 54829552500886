import Widget from "../../../../components/core/Widget";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {FiSearch, FiTrash} from "react-icons/fi";
import React from "react";
import {useTranslation} from "react-i18next";

const Filters = ({onSearch}) => {

    const {t} = useTranslation()

    return (
        <Widget title={t('basics.filters')}>
            <Form
                onSubmit={onSearch} render={({handleSubmit, form}) => (
                <>
                    <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
                        <div className='w-full'>
                            <TextField label={t('partners.nameOf')} name={'partner'} inputType={'text'}/>
                        </div>
                    </div>
                    <div className='text-right'>
                        <Button onClick={() => {
                            form.reset()
                            handleSubmit()
                        }} text={t('basics.resetForm')} rounded={true} outline={true} icon={<FiTrash/>}/>
                        <Button onClick={handleSubmit} text={t('basics.search')} rounded={true} icon={<FiSearch/>}/>
                    </div>
                </>
            )}/>
        </Widget>
    )
}

export default Filters
