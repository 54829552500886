import React, {useState} from 'react'
import Button from "../../../components/Button/Button";
import {apiGetChannelOauth} from "../action";


const SpartaLogin = ({channelId, eventId}) => {

    let [loading, setLoading] = useState(false)

    const onAuthLogin = () => {
        setLoading(true)
        apiGetChannelOauth(channelId, `${channelId}?eventId=${eventId}`, (data) => {
            window.location.href = data.url
        })
    }

    return (
        <div>
            <h6 className={'text-center text-enigoo'}>Pro vyzvednutí vstupenek je potřeba být registrován ve
                SpartaID</h6>
            <div className='text-center mt-4'>
                <Button text={'Přihlásit do SpartaID'} onClick={onAuthLogin} loading={loading}/>
            </div>
        </div>
    )
}

export default SpartaLogin;
