import React from 'react';
import Alert from "../../../../components/core/Alert";
import {useTranslation} from "react-i18next";


const Error = ({error}) => {

    const {t} = useTranslation()

    if (!error) return null;

    if (error.status === 400 && error.type === 'USER_NOT_FOUND') return <Alert size={'sm'} raised={true} borderLeft={true}
                                            color={'border-red-500 text-xs mb-4'}
                                            children={t("errors.userNotFound")}/>
    if (error.status === 400 && error.type === 'LIMIT') return <Alert size={'sm'} raised={true} borderLeft={true}
                                                                               color={'border-red-500 text-xs mb-4'}
                                                                               children={t("errors.changePasswordLimit")}/>
    return <Alert size={'sm'} raised={true} borderLeft={true} color={'border-red-500 text-xs mb-4'}
                  children={t("errors.unexpectedError")}/>
}

export default Error;
