import React from "react";
import ReactExport from "react-export-excel";
import moment from "moment";
import {useTranslation} from "react-i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExportToXls = ({data, exportName = 'export'}) => {

    const {t} = useTranslation()

    const getDataSet = () => {
        let dataSet = [];
        data.forEach((item, key) => {
            dataSet.push({
                ySteps: key !== 0 ? 3 : 0,
                columns: [item.eventName, moment(item.eventDate).format('DD.MM.YYYY HH:mm')],
                data: []
            })

            dataSet.push({
                columns: [t('partners.single'), t('tickets.assigned'), t('tickets.removed'), t('tickets.used')],
                data: getTickets(item.partners)
            })
        })

        return dataSet;
    }

    const getTickets = (data) => {
        let array = [];
        data.forEach(item => {
            let inner = [];
            inner.push(item.name)
            inner.push(item.count)
            inner.push(item.ticketPurchase)
            inner.push(item.ticketEntrance)

            array.push(inner);
        })

        return array;
    }

    if (!data) return null;
    return (
        <ExcelFile filename={exportName + '_' + new Date().getTime()}
                   hideElement={true}>
            <ExcelSheet dataSet={getDataSet()} name="List 1"/>
        </ExcelFile>
    )
}

export default ExportToXls
