import React, {useEffect, useState} from 'react'
import {FiCheckCircle} from "react-icons/fi";


const SpartaFinalPage = () => {

    let [data, setData] = useState(null)

    useEffect(() => {
        let item = localStorage.getItem('oauth_data')
        if (item) {
            setData(JSON.parse(item))
        }
    }, []);

    return (
        <h6 className='text-center text-enigoo'><FiCheckCircle size={20}
                                                               className='inline-block align-middle mr-1'/>
            Objednávka byla úspěšně přídána do SpartaID ({data?.email})
        </h6>
    )
}

export default SpartaFinalPage;
