import React from 'react';
import FormModal from "./FormModal";
import TextField from "../FinalForm/TextField/TextField";
import {useTranslation} from "react-i18next";

const SelectNumberModal = ({visible, onClose, getNumber, maximum}) => {
    const {t} = useTranslation()

    if (!visible) return null

    return <FormModal onSubmit={(values) => getNumber(values.number)} onClose={onClose} visible={visible}
                      title={t('basics.enterCount')} body={
        <>
            <TextField inputType={'number'} label={t('basics.count')} name={'number'} validate={value => {
                if (!value) return true;
                if (value > maximum) return t("tickets.maxPlaces",{max:maximum})
                return false
            }
            }/>
        </>
    }/>
}

export default SelectNumberModal;
