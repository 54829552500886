import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {apiGetPartnerEventTicketList} from "../action";
import Datatable from "../../../../components/core/Datatable/Datatable";
import {Badge} from "../../../../components/core/Badge";
import {FiCheckCircle} from "react-icons/fi";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import ButtonWidget from "./ButtonWidget";
import Filters from "./Filters";
import SelectNumberModal from "../../../../components/Modal/SelectNumberModal";
import Button from "../../../../components/Button/Button";
import {FaMouse} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const PartnerTicketList = () => {

    let [tickets, setTickets] = useState(null)
    let [filtered, setFiltered] = useState(null)
    let [items, setItems] = useState([])
    let [selectModal, setSelectModal] = useState(false)
    let [number, setSelectedNumber] = useState(null)

    const {selectedPartner} = useSelector(state => state.user)
    const {eventId} = useParams()
    const {t} = useTranslation()

    useEffect(() => {
        loadTickets();
    }, [])

    const loadTickets = () => {
        apiGetPartnerEventTicketList(selectedPartner.id, eventId, (data) => setTickets(data))
    }

    const setupSelectedNumber = (number) => {
        if (tickets) {
            if (filtered) {
                setItems([...filtered].slice(0, number))
            }
            setItems([...tickets.tickets].slice(0, number))
        }

        setSelectedNumber(number)
        setSelectModal(false)
    }


    const onSearch = (values) => {
        if (Object.keys(values).length === 0) {
            setFiltered(null)
            return;
        }
        let filter = tickets.tickets.filter(item => {
            return (values?.id ? (parseInt(values.id) === item.id) : true) &&
                (values?.sector ? (values.sector === item.sector) : true) &&
                (values?.row ? (parseInt(values.row) === item.row) : true) &&
                (values?.place ? (parseInt(values.place) === item.place) : true) &&
                (values?.channels ? (item?.channelId && item.channelId.find(_item => _item === values.channels)) : true) &&
                (values?.notSold ? (values.notSold !== item.isSold) : true) &&
                (values?.notInDistributionChannel?(!item.channelId):true)&&
                (values.categoryName ? (item.categoryName === values.categoryName) : true)

        })

        setFiltered([...filter])
    }


    const _renderTable = () => {
        if (!tickets) return null;
        return (
            <>
                <div>
                    <Button text={t('tickets.quickSelect')} icon={<FaMouse/>} onClick={() => setSelectModal(true)}
                            outline={true}/>
                </div>
                <Datatable data={filtered ? filtered : tickets.tickets} setSelectedItems={setItems}
                           numberToSelect={number} columns={[
                    {
                        Header: t('tickets.enigooId'),
                        accessor: 'id'
                    },
                    {
                        Header: t('tickets.sector'),
                        accessor: 'sector'
                    },
                    {
                        Header: t('tickets.row'),
                        accessor: 'row'
                    },
                    {
                        Header: t('tickets.place'),
                        accessor: 'place'
                    },
                    {
                        Header: t('tickets.category'),
                        accessor: 'categoryName'
                    },
                    {
                        Header: t('distributionChannels.plural'),
                        id: 'channels',
                        accessor: item => _renderChannels(item)
                    },
                    {
                        Header: t('tickets.pickup'),
                        id: 'pickup',
                        accessor: item => _renderPickup(item)
                    },
                ]}/>
            </>

        )
    }

    const _renderChannels = (item) => {
        if (!item.channelName) return '-'
        if (item.channelName.length === 0) return "-"
        return item.channelName.map((_item, key) => <Badge key={key + "_" + item.id} rounded={true}>{_item}</Badge>)
    }

    const _renderPickup = (item) => {
        if (item.isSold) return <FiCheckCircle size={20} className={'text-enigoo'}/>
        return "-"
    }

    const _renderCount = () => {
        if (!tickets && !filtered) return ''
        if (filtered) return `(${filtered.length})`
        if (tickets.tickets) return `(${tickets.tickets.length})`
    }

    const getMaximum = () => {
        if (tickets) {
            if (filtered) return filtered.length
            return tickets.tickets.length
        }
        return 0
    }

    const getCategories = () => {
        if (!tickets) return [];
        let array = [];
        tickets.tickets.forEach(item => {
            if (!array.includes(item.categoryName)) {
                array.push(item.categoryName)
            }
        })

        return array;
    }

    if (!eventId) return null
    return (
        <>
            <SectionTitle title={t('events.plural')} subtitle={tickets ? tickets.event.name : '...'}/>
            <Filters onSearch={onSearch} categories={getCategories()}/>
            <ButtonWidget items={items} eventId={eventId} callback={() => {
                setItems([])
                loadTickets()
            }}/>
            <Widget title={t("basics.dataCount",{count:_renderCount()})}>
                <ContentSpinner isSpinning={!tickets}>
                    {_renderTable()}
                </ContentSpinner>
                <SelectNumberModal maximum={getMaximum()}
                                   visible={selectModal} onClose={() => setSelectModal(false)}
                                   getNumber={setupSelectedNumber}/>
            </Widget>
        </>
    )
}

export default PartnerTicketList;
