import React from 'react'
import Button from "../../../components/Button/Button";
import Widget from "../../../components/core/Widget";
import {apiPostChannelOauthLogout} from "../action";

const SpartaBuy = ({oauthData, onSubmit, isValid, loading, channelUrl, logoutUser, hash}) => {


    const onLogout = () => {
        apiPostChannelOauthLogout(channelUrl, {hash},() => {
            logoutUser()

        })
    }

    return (
        <Widget title={'SpartaID'}>
            <div className='text-right font-bold text-enigoo mb-2'>
                Přihlášen - {oauthData?.email}
            </div>
            <div className='text-right font-bold text-enigoo mb-2 underline cursor-pointer'>
                <span onClick={onLogout}>Odhlásit</span>
            </div>
            <div className='text-right'>
                <Button text={'Vyzvednout vstupenky do SpartaID'} onClick={onSubmit} disabled={!isValid}
                        loading={loading}/>
            </div>
        </Widget>
    )
}

export default SpartaBuy;
