import React from "react";
import PropTypes from "prop-types"
import Spinner from "../core/Spinner/Spinner";
import {Link} from 'react-router-dom'

const Button = ({
                    onClick,
                    text,
                    icon,
                    rounded,
                    color,
                    outline,
                    size,
                    loading,
                    disabled,
                    className,
                    linkProps = null,
                    type = 'button'
                }) => {

    const getColor = () => {
        if (outline) {
            switch (color) {
                case "blue":
                    return 'bg-outlined bg-transparent text-blue-500 hover:text-blue-700 border-blue-500 hover:border-blue-700'
                default:
                    return 'bg-outlined bg-transparent text-enigoo border-blue-500 hover:border-blue-700'
            }
        }
        switch (color) {
            case "blue":
                return 'bg-blue-500 hover:bg-blue-600 text-white'
            default:
                return 'bg-enigoo text-enigoo-hover text-white'
        }
    }

    const _renderIcon = () => {
        if (loading) return <Spinner color={outline ? 'enigoo' : 'white'}/>
        if (icon) return <span className='inline-block align-middle'>{icon}</span>
        return null;
    }

    const button = () => (
        <button
            className={`btn btn-${size} ${getColor()} ${rounded ? 'btn-rounded' : ""} ${className}`}
            disabled={loading ? true : disabled}
            type={type}
            onClick={onClick}>
            {_renderIcon()} {text ? text : null}
        </button>
    )

    if (linkProps) {
        return (
            <Link {...linkProps}>
                {button()}
            </Link>
        )
    }

    return button();
}


Button.defaultProps = {
    rounded: true,
    size: 'default',
    loading: false,
    disabled: false,
    className: ''
}

Button.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.element,
    rounded: PropTypes.bool,
    color: PropTypes.oneOf(['blue']),
    size: PropTypes.oneOf(['default', 'large', 'sm']),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    linkProps: PropTypes.object
}

export default Button;
