import React from "react"
import {FiMenu} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {SET_COLLAPSED} from "../../../reducers/admin/config";
import User from "../User";
import LanguageDropDown from "../../Language/LanguageDropDown";
import Button from "../../Button/Button";
import {apiOauthUser, apiOauthUserLogout} from "../../../pages/Admin/Users/action";
import {toast} from "react-hot-toast";

const Navbar = () => {

    const {isCollapsed} = useSelector(state => state.config)
    const dispatch = useDispatch();

    const {user} = useSelector(state => state.user)

    const onOAuthLogout = () => {
        apiOauthUserLogout(() => {
            toast.success('Odhlášeno')
            window.location.reload()
        })
    }

    const _renderSpartaOAuth = () => {
        if (user.data) {
            return (
                <div className={"relative"} style={{marginTop: 5}}>
                    <span onClick={onOAuthLogout}
                          className='font-bold text-enigoo cursor-pointer'>Odhlásit ze SpartaID ({user.data.email})</span>
                </div>
            )
        }
    }

    return (
        <div className="navbar navbar-1 border-b">
            <div className="navbar-inner w-full flex items-center justify-start">
                <button className='mx-4' onClick={() => dispatch({type: SET_COLLAPSED, payload: !isCollapsed})}
                ><FiMenu size={20}/></button>

                <span className='ml-auto'></span>
                {_renderSpartaOAuth()}
                <div className={"relative"}>
                    <LanguageDropDown/>
                </div>
                <User/>
            </div>
        </div>
    )
}

export default Navbar;
