import {api, apiErrorParser} from "../../../api/api";

export const apiGetSuperPartnerEvents = (superPartnerId, success, error, params = {}) => {
    api.get('api/v1/super-partner/' + superPartnerId + '/events', {params: params})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetPartnerEvents = (partnerId, success, error) => {
    api.get('api/v1/partner/' + partnerId + '/events')
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetEventTicketList = (superPartnerId, eventId, subjectId, success, error) => {
    api.get('api/v1/super-partner/' + superPartnerId + '/event/tickets', {params: {eventId, subjectId}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetPartnerEventTicketList = (partnerId, eventId, success, error) => {
    api.get(`api/v1/partner/${partnerId}/events/${eventId}`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiPartnerRemoveTicketFromChannel = (partnerId, eventId, values, success, error) => {
    api.post(`api/v1/partner/${partnerId}/events/${eventId}/channel/remove`, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiAddTicketsToPartner = (superPartnerId, eventId, subjectId, values, success, error) => {
    api.post('api/v1/super-partner/' + superPartnerId + '/event/tickets/partner', values, {
        params: {
            eventId,
            subjectId
        }
    })
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiSuperPartnerGetEventsToCopy = (superPartnerId, eventId, subjectId, success, error) => {
    api.get('api/v1/super-partner/' + superPartnerId + '/event/copy', {params: {eventId, subjectId}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiSuperPartnerPostEventsToCopy = (superPartnerId, eventId, subjectId, eventIds, success, error) => {
    api.post('api/v1/super-partner/' + superPartnerId + '/event/copy', {eventIds: eventIds}, {
        params: {
            eventId,
            subjectId
        }
    })
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}
