import {api, apiErrorParser} from "../../../api/api";

export const apiSuperPartnerGetChannels = (superPartnerId, success, error) => {
    api.get(`api/v1/super-partner/${superPartnerId}/channel`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiPartnerGetChannels = (partnerId, success, error) => {
    api.get(`api/v1/partner/${partnerId}/channel`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiSuperPartnerTicketToChannel = (superPartnerId, eventId, subjectId, values, success, error) => {
    api.post(`api/v1/super-partner/${superPartnerId}/event/tickets/channel`, values, {params: {eventId, subjectId}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiPartnerTicketToChannel = (partnerId, eventId, values, success, error) => {
    api.post(`api/v1/partner/${partnerId}/events/${eventId}/channel`, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiPartnerCreateChannel = (partnerId, values, success, error) => {
    api.post(`api/v1/partner/${partnerId}/channel`, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiSuperPartnerCreateChannel = (superPartnerId, values, success, error) => {
    api.post(`api/v1/super-partner/${superPartnerId}/channel`, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiSuperPartnerRemoveFromChannel = (superPartnerId, values, success, error) => {
    api.post(`api/v1/super-partner/${superPartnerId}/event/tickets/channel/remove`, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiSuperPartnerRemoveFromPartner = (superPartnerId, eventId, subjectId, values, success, error) => {
    api.post(`api/v1/super-partner/${superPartnerId}/event/tickets/partner/remove`, values, {
        params: {
            eventId,
            subjectId
        }
    })
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}
