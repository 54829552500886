import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FiAirplay, FiAlertOctagon, FiChevronDown, FiChevronUp, FiGlobe, FiInfo, FiLogOut, FiUser} from "react-icons/fi";
import Button from "../Button/Button";
import Flag from "react-flagkit";

const LanguageDropDown = () => {

    const languages = [
        {id: "cs", label: "CZ", icon: <Flag country={"CZ"} size={20}/>},
        {id: "sk", label: "SK", icon: <Flag country={"SK"} size={20}/>},
        {id: "en", label: "EN", icon: <Flag country={"GB"} size={20}/>},
        {id: "de", label: "DE", icon: <Flag country={"DE"} size={20}/>},
    ];
    let [hidden, setHidden] = useState(true);
    let [expanded, setExpanded] = useState(false);
    const {t, i18n} = useTranslation()
    const buttonRef = useRef(null)
    const dropdownRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                hidden ||
                buttonRef.current.contains(event.target) ||
                dropdownRef.current.contains(event.target)
            ) {
                return false
            }
            setHidden(!hidden)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [hidden, dropdownRef, buttonRef])

    const handleDropdownClick = () => {
        setHidden(!hidden)
    }

    return (
        <div className="relative" onMouseLeave={()=>{
            setHidden(true)
        }}>
            <button className="flex h-16 w-8 rounded-full ml-2 relative mr-4" onClick={handleDropdownClick}
                    ref={buttonRef}>
                <span className="absolute top-0 left-0 pt-4">
                  <div className="h-8 w-8 rounded-full shadow flex justify-center items-center">
                      <FiGlobe size={20}/> </div>
                </span>
            </button>
            <div ref={dropdownRef}
                 className={`dropdown absolute top-0 right-0 mt-16 ${hidden ? '' : 'open'}`}>
                <div className="dropdown-content w-20 bottom-end">
                    <div className="flex flex-col w-full">
                        <ul className="list-none">
                            {languages.map((item) => (
                                <li className="dropdown-item">
                                    <div
                                        onClick={() => {
                                            i18n.changeLanguage(item.id)
                                            setHidden(true)
                                        }}
                                        className={`flex flex-row items-center justify-start h-10 w-full px-2 cursor-pointer ${item.id===i18n.language&&"font-bold bg-grey-200"}`}>
                                        {item.icon} <span className='mx-2'>{item.label}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default LanguageDropDown
