import React, {useState} from "react";
import Widget from "../../../../components/core/Widget";
import Button from "../../../../components/Button/Button";
import {FiPlus} from "react-icons/fi";
import FormModal from "../../../../components/Modal/FormModal";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import {useSelector} from "react-redux";
import {TYPE_PARTNER, TYPE_SUPER_PARTNER} from "../../../../reducers/admin/user";
import {apiPartnerCreateChannel, apiSuperPartnerCreateChannel} from "../action";
import {toast} from "react-hot-toast";
import SwitchField from "../../../../components/FinalForm/Switch/SwitchField";
import {useTranslation} from "react-i18next";

const ButtonWidget = ({callback}) => {

    let [modal, setModal] = useState(false)
    let [loading, setLoading] = useState(false)

    const {t} = useTranslation()
    const {selectedPartner} = useSelector(state => state.user)

    const onChannelCreate = (values) => {
        setLoading(true)
        if (selectedPartner.type === TYPE_SUPER_PARTNER) {
            apiSuperPartnerCreateChannel(selectedPartner.id, values, () => {
                setModal(false)
                setLoading(false)
                toast.success(t('distributionChannels.wasCreate'))
                callback()
            })
        } else if (selectedPartner.type === TYPE_PARTNER) {
            apiPartnerCreateChannel(selectedPartner.id, values, () => {
                setModal(false)
                setLoading(false)
                toast.success(t('distributionChannels.wasCreate'))
                callback()
            })
        }
    }

    return (
        <Widget title={t('actions.plural')}>
            <Button onClick={() => setModal(true)} icon={<FiPlus/>} text={t("distributionChannels.add")}
                    className='mr-1'/>

            {modal &&
                <FormModal onSubmit={onChannelCreate} onClose={() => setModal(false)}
                           initialValues={{withSelect: false}}
                           visible={modal} loading={loading}
                           title={t("distributionChannels.create")} body={
                    <>
                        <TextField inputType={'text'} label={t('basics.name')} name={'name'} validate={value => !value}/>
                        <SwitchField label={t("distributionChannels.canSelectPlace")} name={'withSelect'}/>
                    </>

                }/>
            }
        </Widget>
    )
}

export default ButtonWidget;
