import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {switchError} from "../../../../api/api";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import {Form, FormSpy} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {apiCreatePartner, apiGetIcoCompany, apiGetSuperPartnersSubjects} from "../action";
import {useSelector} from "react-redux";
import SelectField from "../../../../components/FinalForm/SelectField/SelectField";
import {useTranslation} from "react-i18next";

const CreatePartner = () => {


    const {selectedPartner} = useSelector(state => state.user)

    let [loading, setLoading] = useState(false)
    let [subjects, setSubjects] = useState(null)

    let [isIcoComplete, setIcoComplete] = useState(false)
    let [company, setCompany] = useState(false)

    const navigate = useNavigate()
    const {t} = useTranslation()


    useEffect(() => {
        apiGetSuperPartnersSubjects(selectedPartner.id, (data) => setSubjects(data))
    }, [])


    useEffect(() => {
        if (isIcoComplete) {
            apiGetIcoCompany(isIcoComplete, (data) => {
                setCompany(data)
            }, err => {
                setIcoComplete(false)
                toast.error(t('partners.idNumberNotFound'))
            })
        }
    }, [isIcoComplete])

    const onSubmit = (values) => {
        setLoading(true)
        apiCreatePartner(selectedPartner.id, values, () => {
            toast.success(t('partners.created'))
            navigate('/admin/super-partner/partners')
        }, err => {
            setLoading(false)
            switchError(err, [], true)
        })
    }

    return (
        <>
            <SectionTitle title={t('partners.plural')} subtitle={t('partners.add')}/>
            <Widget>

                <Form onSubmit={onSubmit} render={({handleSubmit, values}) => (
                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                            <div className='w-full'>
                                <TextField label={t("partners.nameOf")} name={'name'} inputType={'text'}
                                           validate={value => !value}/>
                            </div>
                            <div className='w-full'>
                                <SelectField placeholder={t("placeholders.subject")} label={t("partners.subjectFor")}
                                             options={subjects} name={'subjectIds'}
                                             isMulti={true}
                                             validate={true}/>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                            <div className='w-full'>
                                <SelectField label={t('partners.typeOf')} name={'type'} isMulti={false} validate={true}
                                             options={[
                                                 {id: 1, name: t('partners.physicalPerson')},
                                                 {id: 2, name: t('partners.company')}
                                             ]}/>
                            </div>
                        </div>
                        {values && values.type === 1 &&
                            <>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
                                    <div className='w-full'>
                                        <TextField label={t("partners.nameOfPhysicalPerson")} name={'partnerName'}
                                                   validate={value => !value}/>
                                    </div>
                                    <div className='w-full'>
                                        <TextField label={t('basics.email')} name={'email'}/>
                                    </div>
                                    <div className='w-full'>
                                        <TextField label={t('basics.phone')} name={'telephone'}/>
                                    </div>
                                </div>
                            </>
                        }
                        {values && values.type === 2 &&
                            <>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
                                    <TextField label={t('partners.idNumber')} name={'ico'} disabled={isIcoComplete}/>
                                </div>
                                {company &&
                                    <div className='mb-4'>
                                        <h4>{company.company}</h4>
                                        <div>{t("partners.street")}: <span className='font-bold text-enigoo'>{company.street} {company.streetNumber}</span></div>
                                        <div>{t("partners.city")}: <span className='font-bold text-enigoo'>{company.city}</span></div>
                                        <div>{t("partners.zip")}: <span className='font-bold text-enigoo'>{company.zip}</span></div>
                                    </div>
                                }
                            </>
                        }
                        {values && values.type &&
                            <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                                <div className='w-full'>
                                    <TextField label={t('partners.addInfo')} name={'information'}/>
                                </div>
                            </div>
                        }
                        <div className='text-right'>
                            <Button rounded={true} size={'default'} onClick={handleSubmit} text={t("basics.save")}
                                    loading={loading}/>
                        </div>

                        <FormSpy subscription={{values: true}} onChange={(values) => {
                            if (values.values.type === 2 && values.values.ico && values.values.ico.length === 8) {
                                setIcoComplete(values.values.ico)
                            }
                        }}/>
                    </>
                )}/>
            </Widget>
        </>
    )
}

export default CreatePartner;
