import React from "react";
import Widget from "../../../components/core/Widget";
import TextField from "../../../components/FinalForm/TextField/TextField";
import {FormSpy, Form} from "react-final-form";
import TelephoneInput from "../../../components/FinalForm/TelephoneInput/TelephoneInput";
import {useDispatch, useSelector} from "react-redux";
import {CHANNEL_SET_USER} from "../../../reducers/channel/channel";
import * as EmailValidator from "email-validator";
import SwitchField from "../../../components/FinalForm/Switch/SwitchField";
import {useTranslation} from "react-i18next";

const Customer = ({ticketCount}) => {

    const {isSubmitted} = useSelector(state => state.channel)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    return (
        <Widget title={t('customers.single')}>
            <Form onSubmit={values => console.log(values)} render={({handleSubmit}) => (
                <>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                        <div className='w-full'>
                            <TextField label={t('basics.firstname')} name={'firstname'} inputType={'text'}
                                       renderErrorWithoutSubmit={true} instantRenderError={isSubmitted}
                                       validate={value => !value}/>
                        </div>
                        <div className='w-full'>
                            <TextField label={t('basics.surname')} name={'lastname'} inputType={'text'}
                                       instantRenderError={isSubmitted}
                                       renderErrorWithoutSubmit={true}
                                       validate={value => !value}/>
                        </div>
                        <div className='w-full'>
                            <TextField label={t('basics.email')} name={'email'} inputType={'text'}
                                       instantRenderError={isSubmitted}
                                       renderErrorWithoutSubmit={true}
                                       validate={value => {
                                           if (!value) return true;
                                           if (!EmailValidator.validate(value)) return t("errors.fieldMustBeEmail")
                                           return false;
                                       }}/>
                        </div>
                        <div className='w-full'>
                            <TelephoneInput label={t('basics.phone')} name={'telephone'} inputType={'text'}
                                            instantRenderError={isSubmitted}
                                            renderErrorWithoutSubmit={true}
                                            validate={value => !value}/>
                        </div>
                    </div>
                    <div>
                        <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                            <SwitchField name={'singlePdf'} disabled={ticketCount > 20}
                                         label={t("orders.singleFile")}/>
                        </div>
                    </div>
                    <FormSpy subscription={{values: true, valid: true}} onChange={({values, valid}) => {
                        dispatch({type: CHANNEL_SET_USER, payload: {...values, isValid: valid}})
                    }}/>
                </>
            )}/>
        </Widget>
    )
}

export default Customer
