import React from "react";
import PropTypes from 'prop-types';
import {Field} from "react-final-form"
import Select from 'react-select'
import Spinner from "../../core/Spinner/Spinner";
import {isBoolean} from "@craco/craco/lib/utils";
import {useTranslation} from "react-i18next";

const SelectField = ({...props}) => {
    const {t} = useTranslation();

    const _renderError = (meta) => {
        if (meta.touched && meta.error) {
            return <div className='form-error'>{meta.error === true ? t("errors.requireField") : meta.error}</div>
        }

        return null;
    }


    const getOptions = () => {
        if (props.customOptions) return props.customOptions;
        let array = [];
        props.options.map(item => {
            array.push({value: item.id, label: item.name})
        })

        return array;
    }

    const onFormat = (value) => {
        if (!value) return undefined;
        if (props.isMulti) {
            let array = [];
            value.map(item => {
                let find = props.options.find(_item => _item.id === item.value)
                array.push({value: find.id, label: find.name})
            })

            if (array.length === 0) return undefined

            return array;
        }

        let find = props.options.find(item => item.id === value.value)
        return {value: find.id, label: find.name}

    }

    const onParse = (value) => {
        if (!value) return undefined;
        if (props.isMulti) {
            return value.map(item => item.value)
        }
        return value.value;
    }

    return (
        <Field {...props} name={props.name} validate={value => {
            if (props.validate === true) {
                if (!value) {
                    return value !== 0;
                }
                if (props.isMulti && value.length === 0) return true
            }
            return false;
        }} render={({input, meta}) => (
            <div className='form-element'>
                {props.label && <div className="form-label">{props.label}{(props.validate ? "*" : "")}</div>}
                <Select
                    {...props}
                    options={props.options ? getOptions() : []}
                    onChange={(value) => {
                        input.onChange(value)
                    }}
                    placeholder={props.loading ?
                        <Spinner color={'#4f4f4f'}/> : (props.placeholder ? props.placeholder : t('placeholders.choose'))}
                    isDisabled={props.loading ? true : props.disabled}
                    styles={{
                        control: (provided) => {
                            let border = {}
                            if (meta.touched && meta.error) {
                                border = {border: '1px solid #f44336'}
                            }
                            return {...provided, ...border}

                        }
                    }}
                />
                {_renderError(meta)}
            </div>
        )}
               format={onFormat}
               parse={onParse}
        />

    )
}

SelectField.defaultProps = {
    label: null,
    options: [],
    loading: false,
}

SelectField.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    loading: PropTypes.bool
}

export default SelectField;
