export const SET_COLLAPSED = "SET_COLLAPSED";


export default function reducer(
    state = {
        isCollapsed: false,
    },
    action
) {
    switch (action.type) {
        case SET_COLLAPSED:
            return {
                ...state,
                isCollapsed: action.payload,
            }
    }

    return state
}
