import Widget from "../../../../../components/core/Widget";
import {Form} from "react-final-form";
import TextField from "../../../../../components/FinalForm/TextField/TextField";
import PartnerField from "../../../../../components/FinalForm/ApiFields/PartnerField";
import Button from "../../../../../components/Button/Button";
import {FiSearch, FiTrash} from "react-icons/fi";
import React from "react";
import SelectField from "../../../../../components/FinalForm/SelectField/SelectField";
import DateClassic from "../../../../../components/Date/DateClassic";
import {useTranslation} from "react-i18next";

const Filters = ({onSearch, events}) => {

    const {t} = useTranslation()


    const getOptions = () => {
        let array = [];
        events.map((item) => {
            array.push({
                id: item.eventId,
                name: <span><span
                    className={'text-enigoo'}>{item.subjectName}</span> | {item.eventName}<sup><DateClassic
                    dateIso={item.eventDate}
                    format={'LLL'}/></sup></span>
            })
        })

        return array;
    }

    return (
        <Widget title={t('basics.filters')}>
            <Form
                onSubmit={onSearch} render={({handleSubmit, form}) => (
                <>
                    <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
                        <div className='w-full'>
                            <TextField label={'#ID'} name={'id'} inputType={'text'}/>
                        </div>
                        <div className='w-full'>
                            <TextField label={t('customers.single')} name={'customer'} inputType={'text'}/>
                        </div>
                        <div className='w-full'>
                            <PartnerField label={t('partners.plural')} name={'partners'} isClearable={true} isMulti={false}
                                          placeholder={t("placeholders.partners")}/>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                        <div className='w-full'>
                            <SelectField label={t('events.single')} name={'event'} options={getOptions()} isClearable={true}/>
                        </div>
                    </div>
                    <div className='text-right'>
                        <Button onClick={() => {
                            form.reset()
                            handleSubmit()
                        }} text={t('basics.resetForm')} rounded={true} outline={true} icon={<FiTrash/>}/>
                        <Button onClick={handleSubmit} text={t('basics.search')} rounded={true} icon={<FiSearch/>}/>
                    </div>
                </>
            )}/>
        </Widget>
    )
}

export default Filters
