import React, {useEffect, useState} from 'react';
import CenteredForm from "../../../../components/core/CenteredForm";
import CenteredLayout from "../../../../components/Layouts/CenteredLayout";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {apiChangePassword, apiGetResetPassword} from "../action";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import LanguageDropDown from "../../../../components/Language/LanguageDropDown";

const PasswordReset = () => {

    let [loading, setLoading] = useState(false)
    let [verified, setVerified] = useState(null)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email')
    const hash = searchParams.get('h')
    const {t} = useTranslation()

    useEffect(() => apiGetResetPassword(email, hash, () => {
        setVerified(true)
    }, () => {
        setVerified(false)
    }), [])

    const onSubmit = (values) => {
        setLoading(true)
        apiChangePassword(email, hash, values.password, () => {
            toast.success(t('resetPassword.passwordChanged'))
            navigate('/admin')
        }, err => {
            setLoading(false)
            toast.error(err.message)
        })
    }

    const _renderContent = () => {
        if (verified === null) return <ContentSpinner isSpinning={true}/>
        if (verified === false) return <h4 className='text-enigoo text-center'>
            {t("resetPassword.linkNotFound")}</h4>
        return (
            <CenteredForm
                title={email}
                subtitle={t("resetPassword.changePassword")}
            >
                <div className={"fixed top-0 right-0 mr-2 mt-2"}>
                    <LanguageDropDown/>
                </div>
                <Form onSubmit={onSubmit} render={({handleSubmit, values}) => (
                    <>
                        <form className='form flex flex-wrap w-full'>
                            <div className='w-full'>
                                <TextField label={t('resetPassword.enterPassword')} name={'password'} inputType={'password'}
                                           instantRenderError={true}
                                           validate={value => {
                                               if (!value) return true
                                               if (value.length < 6) return t('resetPassword.min6chars')
                                               return false
                                           }}/>
                            </div>
                            <div className='w-full'>
                                <TextField label={t('resetPassword.enterPasswordAgain')} name={'passwordRepeat'}
                                           instantRenderError={true} autocomplete={'off'} validate={value => {
                                    if (!value) return true;
                                    if (value !== values.password) return t("resetPassword.passwordsNotTheSame")
                                    return false;
                                }}
                                           inputType={'password'}/>
                            </div>
                            <div className='w-full text-right'>
                                <Button text={t("resetPassword.doChangePassword")} onClick={handleSubmit} rounded={true} loading={loading}/>
                            </div>
                        </form>
                    </>
                )}/>
            </CenteredForm>
        )
    }


    return (
        <CenteredLayout>
            {_renderContent()}
        </CenteredLayout>
    )
}

export default PasswordReset;
