import React from 'react'
import Widget from "../../../components/core/Widget";
import {useTranslation} from "react-i18next";

const NoTickets = () => {
    const {t} = useTranslation()
    return (
        <Widget>
            <h6 className='text-enigoo text-center'>{t("tickets.noTicketsAvailableYet")}</h6>
        </Widget>
    )
}

export default NoTickets;
