import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {switchError} from "../../../../api/api";
import {apiPartnerTicketToChannel} from "../../Channels/action";
import Widget from "../../../../components/core/Widget";
import Button from "../../../../components/Button/Button";
import {FiBox, FiSend} from "react-icons/fi";
import FormModal from "../../../../components/Modal/FormModal";
import ChannelField from "../../../../components/FinalForm/ApiFields/ChannelField";
import {apiPartnerRemoveTicketFromChannel} from "../action";
import {useTranslation} from "react-i18next";

const ButtonWidget = ({items, eventId, callback}) => {

    let [addChannel, setAddChannel] = useState(false)
    let [loadingChannel, setLoadingChannel] = useState(false)
    let [loadingRemoveChannel, setLoadingRemoveChannel] = useState(false)

    const {selectedPartner} = useSelector(state => state.user)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const getIds = () => items.map(item => item.id)
    const isSomeSold = () => items.find(item => item.isSold === true)


    const onAddChannel = (values) => {
        setLoadingChannel(true)
        apiPartnerTicketToChannel(selectedPartner.id, eventId, {
            channelIds: values.channelIds,
            ticketIds: getIds()
        }, () => {
            callback()
            setLoadingChannel(false)
            setAddChannel(false)
        }, err => {
            setLoadingChannel(false)
            setAddChannel(false)
            switchError(err, [], true)
        })
    }

    const onRemoveChannel = () => {
        setLoadingRemoveChannel(true)
        apiPartnerRemoveTicketFromChannel(selectedPartner.id, eventId, {ticketIds: getIds()}, () => {
            callback()
            setLoadingRemoveChannel(false)
        })
    }

    return (
        <Widget title={t('actions.single')}>
            <div>
                <Button onClick={() => setAddChannel(true)} icon={<FiBox/>} text={t("tickets.addToDistributionChannel")}
                        loading={loadingChannel}
                        className='mr-1'
                        disabled={!items.length}/>
                <Button
                    onClick={() => navigate(`/admin/partner/events/${eventId}/order?ticketIds=${getIds().join(',')}`)}
                    icon={<FiSend/>} text={t("tickets.pickupTickets")} className='mr-1'
                    disabled={!items.length || isSomeSold()}/>

                <FormModal onSubmit={onAddChannel} visible={addChannel} title={'Přiřadit do distribučního kanálu'}
                           loading={loadingChannel}
                           body={<>
                               <ChannelField name={'channelIds'} superPartnerId={selectedPartner.id}
                                             label={t('distributionChannels.single')}
                                             validate={true}
                                             isMulti={true}/>
                           </>}
                           onClose={() => setAddChannel(false)}/>
            </div>
            <div className='mt-2'>
                <Button onClick={onRemoveChannel} outline={true} icon={<FiBox/>}
                        text={t('tickets.removeFromDistributionChannel')}
                        loading={loadingRemoveChannel} className='mr-1'
                        disabled={!items.length}/>
            </div>


        </Widget>
    )
}

export default ButtonWidget;
