import React, {useState} from 'react'
import Alert from "../../../components/core/Alert";
import Spinner from "../../../components/core/Spinner/Spinner";
import {apiResendVerificationEmail} from "./action";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";


const Error = ({error, setError}) => {

    let [loading, setLoading] = useState(false)

    const {t} = useTranslation()

    const onResend = () => {
        if (!loading) {
            setLoading(true)
            apiResendVerificationEmail({email: error.data.email, resend: error.data.resend}, () => {
                setLoading(false)
                toast.success(t('basics.mailSent'))
                setError(false)
            })
        }
    }

    if (!error) return null;
    if (error.status === 400) return <Alert size={'sm'} raised={true} borderLeft={true}
                                            color={'border-red-500 text-xs mb-4'}
                                            children={<span>{t("verifyUser.userNotVerifiedYet")}<span onClick={onResend}
                                                                                               className='text-enigoo cursor-pointer'>{t("verifyUser.sendVerifyEmailAgain")}</span>
                                                {loading && <span
                                                    className='pl-2'><Spinner color={'enigoo'}/></span>}</span>}/>
    return <Alert size={'sm'} raised={true} borderLeft={true} color={'border-red-500 text-xs mb-4'}
                  children={t("verifyUser.invalidUserNameOrPassword")}/>
}

export default Error;
