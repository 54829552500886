import React, {useEffect, useState} from 'react'
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import {useSearchParams} from "react-router-dom"
import {apiGetEventTicketList} from "../action";
import {useSelector} from "react-redux";
import Datatable from "../../../../components/core/Datatable/Datatable";
import {FiCheckCircle, FiCopy} from "react-icons/fi";
import Filters from "./Filters";
import ButtonWidget from "./ButtonWidget";
import {Badge} from "../../../../components/core/Badge";
import Button from "../../../../components/Button/Button";
import {FaMouse} from "react-icons/fa";
import SelectNumberModal from "../../../../components/Modal/SelectNumberModal";
import CopyEventModal from "./CopyEventModal";
import {useTranslation} from "react-i18next";

const TicketList = () => {

    let [tickets, setTickets] = useState(null)
    let [filtered, setFiltered] = useState(null)
    let [items, setItems] = useState([])
    let [selectModal, setSelectModal] = useState(false)
    let [number, setSelectedNumber] = useState(null)
    let [notSold, onNotSold] = useState(true)
    let [notPartner, setNotPartner] = useState(true)
    let [copyModal, setCopyModal] = useState(false)

    const {selectedPartner} = useSelector(state => state.user)
    const {t} = useTranslation();

    const [searchParams] = useSearchParams();
    const eventId = searchParams.get('eventId')
    const subjectId = searchParams.get('subjectId')

    useEffect(() => {
        loadTickets();
    }, [])

    const loadTickets = () => {
        if (eventId && subjectId) {
            apiGetEventTicketList(selectedPartner.id, eventId, subjectId, (data) => {
                setTickets(data)
                onSearch({}, data)
            })
        }
    }


    const onSearch = (values, data = undefined) => {
        let _tickets = tickets;
        if (Object.keys(values).length === 0 && !notSold && !notPartner) {
            setFiltered(null)
            return;
        }
        if (data) {
            _tickets = data;
        }
        let filter = _tickets.tickets.filter(item => {
            return (values?.id ? (parseInt(values.id) === item.id) : true) &&
                (values?.sector ? (values.sector === item.sector) : true) &&
                (values?.row ? (parseInt(values.row) === item.row) : true) &&
                (values?.place ? (parseInt(values.place) === item.place) : true) &&
                (values?.partners ? (item?.partnersId && item.partnersId.find(_item => _item === values.partners)) : true) &&
                (notSold ? !item.isSold : true) &&
                (notPartner ? (!item.partnersId) : true) &&
                (values.categoryName ? (item.categoryName === values.categoryName) : true)

        })

        setFiltered([...filter])
    }


    const _renderTable = () => {
        if (!tickets) return null;
        return (
            <>
                <div>
                    <Button text={t('tickets.quickSelect')} icon={<FaMouse/>} onClick={() => setSelectModal(true)}
                            outline={true}/>
                </div>
                <Datatable data={filtered ? filtered : tickets.tickets} numberToSelect={number}
                           setSelectedItems={setItems} columns={[
                    {
                        Header: t('tickets.enigooId'),
                        accessor: 'id'
                    },
                    {
                        Header: t('tickets.sector'),
                        accessor: 'sector'
                    },
                    {
                        Header: t('tickets.row'),
                        accessor: 'row'
                    },
                    {
                        Header: t('tickets.place'),
                        accessor: 'place'
                    },
                    {
                        Header: t('tickets.category'),
                        accessor: 'categoryName'
                    },
                    {
                        Header: t('partners.plural'),
                        id: 'partners',
                        accessor: item => _renderPartners(item)
                    },
                    {
                        Header: t('distributionChannels.plural'),
                        id: 'channels',
                        accessor: item => _renderChannels(item)
                    },
                    {
                        Header: t('tickets.pickup'),
                        id: 'pickup',
                        accessor: item => _renderPickup(item)
                    },
                ]}/>
            </>

        )
    }

    const _renderChannels = (item) => {
        if (!item.channelName) return '-'
        if (item.channelName.length === 0) return "-"
        return item.channelName.map((_item, key) => <Badge key={key + "_" + item.id} rounded={true}>{_item}</Badge>)
    }

    const _renderPickup = (item) => {
        if (item.isSold) return <FiCheckCircle size={20} className={'text-enigoo'}/>
        return "-"
    }

    const _renderPartners = (item) => {
        if (!item.partnersName) return '-'
        if (item.partnersName.length === 0) return "-"
        return item.partnersName.map((_item, key) => <Badge key={key + "_" + item.id} rounded={true}>{_item}</Badge>)
    }

    const _renderCount = () => {
        if (!tickets && !filtered) return ''
        if (filtered) return `${filtered.length}`
        if (tickets.tickets) return `(${tickets.tickets.length})`
    }

    const _renderTotal = () => {
        if (!tickets) return '';
        return `${tickets.tickets.length}`
    }


    const setupSelectedNumber = (number) => {
        if (tickets) {
            if (filtered) {
                setItems([...filtered].slice(0, number))
            }
            setItems([...tickets.tickets].slice(0, number))
        }

        setSelectedNumber(number)
        setSelectModal(false)
    }

    const getMaximum = () => {
        if (tickets) {
            if (filtered) return filtered.length
            return tickets.tickets.length
        }
        return 0
    }

    const getCategories = () => {
        if (!tickets) return [];
        let array = [];
        tickets.tickets.forEach(item => {
            if (!array.includes(item.categoryName)) {
                array.push(item.categoryName)
            }
        })

        return array;
    }

    if (!eventId || !subjectId) return null
    return (
        <>
            <SectionTitle title={t('events.plural')} subtitle={tickets ? <span><span
                className='text-enigoo'>{tickets.event.subjectName}</span> | {tickets.event.name}</span> : '...'}/>
            <div className='text-right'>
                <Button className='mb-2' text={t('events.toCopy')} icon={<FiCopy/>}
                        onClick={() => setCopyModal(true)}/>
            </div>
            <Filters onSearch={onSearch} notSold={notSold} onNotSold={onNotSold} notPartner={notPartner}
                     onNotPartner={setNotPartner} categories={getCategories()}/>
            <ButtonWidget items={items} eventId={eventId} subjectId={subjectId} callback={() => {
                setItems([])
                loadTickets()
            }}/>
            <Widget title={t("tickets.dataSumOf", {
                count: _renderCount(),
                total: _renderTotal()
            })}>
                <ContentSpinner isSpinning={!tickets}>
                    {_renderTable()}
                </ContentSpinner>
                <SelectNumberModal maximum={getMaximum()}
                                   visible={selectModal} onClose={() => setSelectModal(false)}
                                   getNumber={setupSelectedNumber}/>
            </Widget>
            {copyModal &&
                <CopyEventModal eventId={eventId} subjectId={subjectId} onClose={() => setCopyModal(false)}
                                callback={loadTickets}
                                visible={copyModal}/>
            }
        </>
    )
}

export default TicketList;
