import React, {useEffect, useState} from "react"
import {useLocation, useParams} from "react-router-dom";
import CenteredLayout from "../../components/Layouts/CenteredLayout";
import ContentSpinner from "../../components/core/Spinner/ContentSpinner";
import {apiChannelGetEvent, apiChannelGetEvents} from "./action";
import Events from "./Events/Events";
import Order from "./Order/Order";
import Error from "./Error";
import {switchError} from "../../api/api";
import LanguageDropDown from "../../components/Language/LanguageDropDown";

const ChannelApp = () => {

    let [loading, setLoading] = useState(true)
    let [data, setData] = useState(null)
    let [error, setError] = useState(null)
    let [eventId, setEventId] = useState(null)
    let [step, setStep] = useState(0)

    const {channelId} = useParams()
    const {search} = useLocation()


    useEffect(() => {
        if (data && !eventId) {
            let params = new URLSearchParams(search)
            if (params.get('oauth')) {
                localStorage.setItem('oauth', params.get('oauth'))
                localStorage.setItem('oauth_data', atob(params.get('data')))
            }
            if (params.get('eventId')) {
                onEventClick(params.get('eventId'))
            }
        }
    }, [data]);

    useEffect(() => apiChannelGetEvents(channelId, (data) => {
        setData(data)
        setLoading(false)
    }, error => {
        setError(error)
        setLoading(false)
    }), [])


    const _renderContent = () => {
        if (error) return <Error error={error}/>
        if (loading) return null;
        switch (step) {
            case 0:
                return <Events data={data} onEventClick={onEventClick}/>
            case 1:
                return <Order data={data} channelId={channelId} eventId={eventId}
                              callback={() => onEventClick(eventId)}/>
        }
    }

    const onEventClick = (eventId) => {
        setLoading(true)
        setEventId(eventId)
        apiChannelGetEvent(channelId, eventId, (data) => {
            setData(data)
            setStep(1)
            setLoading(false)
        }, err => {
            setStep(0)
            switchError(err, null, true)
            setLoading(false)
        })
    }

    return (
        <CenteredLayout>
            <div className={"fixed top-0 right-0 mr-2 mt-2"}>
                <LanguageDropDown/>
            </div>
            <div className='lg:w-2/3 lg:m-0 bg-grey-50 border-grey-300 border rounded w-full m-3'>
                <div className='p-4'>
                    <ContentSpinner isSpinning={loading}>
                        {_renderContent()}
                    </ContentSpinner>
                </div>

            </div>
        </CenteredLayout>
    )
}

export default ChannelApp;
