import React, {useEffect, useState} from "react";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import Table from "../../../../components/Table/Table";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import {apiGetSuperPartners} from "../action";
import {Badge} from "../../../../components/core/Badge";
import {useTranslation} from "react-i18next";


const SuperPartnerList = () => {

    let [superPartners, setSuperPartners] = useState(false)

    const {t} = useTranslation()

    useEffect(() => {
        apiGetSuperPartners(data => {
            setSuperPartners(data)
        })
    }, [])

    const getSubjects = (array) => array.map(item => (<Badge size={'sm'} rounded={true} color='bg-blue-500 text-white mx-1' children={item.name} />))

    return (
        <>
            <SectionTitle title={t('basics.system')} subtitle={t('superPartners.plural')}/>
            <Widget>
                <ContentSpinner isSpinning={!superPartners}>
                    <Table
                        data={superPartners}
                        columns={[
                            {Header: t('superPartners.single'), accessor: 'name'},
                            {Header: t('subjects.plural'), accessor: item => getSubjects(item.subjects)},
                        ]}
                    />
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default SuperPartnerList
