import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import 'moment/locale/cs'
import 'moment/locale/sk'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import {useTranslation} from "react-i18next";

const DateClassic = ({dateIso, format = 'LLL'}) => {
    const {t,i18n} = useTranslation();

    return moment(dateIso).locale(i18n.language).format(format);
}

DateClassic.propTypes = {
    dateIso: PropTypes.string.isRequired,
    format: PropTypes.string
}

export default DateClassic;
