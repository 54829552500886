import React from "react";
import {useTranslation} from "react-i18next";

const Dashboard = () => {



    const {t} = useTranslation()

    return (
        <div>
            <h2 className='text-center text-enigoo'>{t("basics.welcome")}</h2>
        </div>
    );
}

export default Dashboard;
