import React from 'react'
import Modal from "./Modal";
import {Form} from "react-final-form";
import PropTypes from 'prop-types'

const FormModal = ({onSubmit, initialValues, ...props}) => {

    if (!props.visible) return null
    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} render={({handleSubmit}) => (
            <Modal {...props} onSave={handleSubmit}/>
        )}/>
    )
}

FormModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    body: PropTypes.oneOfType(['element', 'string']),
    title: PropTypes.string
}

export default FormModal
