import React, {useState} from 'react';
import CenteredLayout from "../../../../components/Layouts/CenteredLayout";
import CenteredForm from "../../../../components/core/CenteredForm";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import * as EmailValidator from "email-validator";
import {useNavigate} from "react-router-dom";
import {apiResetPassword} from "../action";
import {toast} from "react-hot-toast";
import Error from "./Error";
import {useTranslation} from "react-i18next";
import LanguageDropDown from "../../../../components/Language/LanguageDropDown";

const PasswordResetCreate = () => {

    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(false)

    const navigate = useNavigate()
    const {t} = useTranslation();

    const onSubmit = (values) => {
        setLoading(true)
        apiResetPassword(values, () => {
            toast.success(t('resetPassword.nextStepsSentOnMail',{email:values.email}))
            navigate('/admin')
        }, err => {
            setLoading(false)
            setError(err)
        })
    }

    return (
        <CenteredLayout>
            <CenteredForm
                subtitle={t("login.forgottenPassword")}
            >
                <div className={"fixed top-0 right-0 mr-2 mt-2"}>
                    <LanguageDropDown/>
                </div>
                <Error error={error}/>
                <Form onSubmit={onSubmit} render={({handleSubmit}) => (
                    <>
                        <form className='form flex flex-wrap w-full'>
                            <div className='w-full'>
                                <TextField label={t('resetPassword.enterEmail')} name={'email'} instantRenderError={true}
                                           inputType={'email'} validate={value => {
                                    if (!value) return true;
                                    if (!EmailValidator.validate(value)) return t("errors.fieldMustBeEmail")
                                    return false;
                                }}/>
                            </div>
                            <div className='w-full text-right'>
                                <Button text={t("basics.accept")} onClick={handleSubmit} rounded={true} loading={loading}/>
                            </div>
                        </form>
                    </>
                )}/>
            </CenteredForm>
        </CenteredLayout>
    )
}

export default PasswordResetCreate;
