import React from "react";
import Widget from "../../../components/core/Widget";
import DateClassic from "../../../components/Date/DateClassic";
import {useTranslation} from "react-i18next";

const Event = ({event}) => {

    const {t} = useTranslation()

    return (
       <Widget title={t('events.single')}>
           <h4 className='text-enigoo'>{event.name}</h4>
           <span><DateClassic dateIso={event.startTime} /></span>
       </Widget>
    )
}

export default Event
