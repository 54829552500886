import React, {useEffect, useState} from 'react'
import {apiPartnerGetChannels, apiSuperPartnerGetChannels} from "../action";
import {useSelector} from "react-redux";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import Table from "../../../../components/Table/Table";
import ButtonWidget from "./ButtonWidget";
import {TYPE_PARTNER, TYPE_SUPER_PARTNER} from "../../../../reducers/admin/user";
import Button from "../../../../components/Button/Button";
import {FiShare} from "react-icons/fi";
import {useTranslation} from "react-i18next";

const ChannelList = () => {

    let [data, setData] = useState(null)

    const {selectedPartner} = useSelector(state => state.user)
    const {t} = useTranslation()

    useEffect(() => {
        getChannels()
    }, [])

    const getChannels = () => {
        if (selectedPartner.type === TYPE_SUPER_PARTNER) {
            apiSuperPartnerGetChannels(selectedPartner.id, (data) => setData(data))
        } else if (selectedPartner.type === TYPE_PARTNER) {
            apiPartnerGetChannels(selectedPartner.id, (data) => setData(data))
        }
    }

    const _renderTable = () => {
        if (!data) return null;
        return (
            <Table emptyText={t('distributionChannels.noChannels')} data={data} columns={[
                {
                    Header: t('basics.name'),
                    accessor: 'name'
                },
                {
                    Header: 'URL',
                    accessor: 'url'
                },
                {
                    Header: '',
                    id: 'buttons',
                    accessor: item => _renderButtons(item)
                }
            ]}/>
        )
    }

    const _renderButtons = (item) => {
        return (
            <div className='text-right'>
                <Button className='mr-1' linkProps={{
                    to: '/channel/' + item.url,
                    target: '_blank'
                }} text={t('basics.open')} rounded={true} icon={<FiShare/>}/>
                {/*
                <Button className='mr-1' linkProps={{
                    to: '' + item.id,
                }} text={'Detail'} rounded={true}/>
                */}
            </div>

        )
    }

    return (
        <>
            <SectionTitle title={t('distributionChannels.distribution')} subtitle={t('distributionChannels.plural')}/>
            <ButtonWidget callback={getChannels}/>
            <Widget>
                <ContentSpinner isSpinning={!data}>
                    {_renderTable()}
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default ChannelList;
