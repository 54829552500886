import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
    apiGetPartnerSpecificTickets,
    apiPartnerOrder,
    apiPartnerOrderNoCustomer,
    apiSuperPartnerOrderNoCustomer
} from "../action";
import {toast} from "react-hot-toast";
import FileSaver from 'file-saver'
import {switchError} from "../../../../api/api";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import OrderForm from "../SuperPartner/OrderForm";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import Table from "../../../../components/Table/Table";
import Alert from "../../../../components/core/Alert";
import {FiInfo} from "react-icons/fi";
import {useTranslation} from "react-i18next";

const PartnerCreateOrder = () => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(false)
    let [initialValues, setInitialValues] = useState({})

    const {selectedPartner} = useSelector(state => state.user)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const {eventId} = useParams()
    const [searchParams] = useSearchParams();
    const ticketIds = searchParams.get('ticketIds')


    useEffect(() => {
        getCustomer()
        if (ticketIds) {
            apiGetPartnerSpecificTickets(selectedPartner.id, eventId, {ticketIds: ticketIds.split(',').map(Number)}, (data) => setData(data))
        }
    }, [])

    const getCustomer = () => {
        let item = localStorage.getItem('saveCustomer')
        if (item) {
            setInitialValues({
                saveCustomer,
                ...JSON.parse(item)
            })
        }
    }

    const saveCustomer = (values) => {
        if (values.saveCustomer) {
            localStorage.setItem('saveCustomer', JSON.stringify({
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
                telephone: values.telephone ? values.telephone : null,
            }))
        } else {
            localStorage.removeItem('saveCustomer')
        }
    }

    const onNoPdfSubmit = (values) => {
        apiPartnerOrderNoCustomer(selectedPartner.id, eventId, {
            ticketIds: ticketIds.split(',').map(Number),
        }, (data) => {
            toast.success(t("tickets.ticketsWasPickup"))

            navigate(`/admin/partner/events/${eventId}/tickets`)
        })
    }

    const onOrderSubmit = (values) => {
        if (data?.noPdf) {
            onNoPdfSubmit(values)
        } else {
            onClassicOrderSubmit(values)
        }
    }

    const onClassicOrderSubmit = (values) => {
        setLoading(true)
        apiPartnerOrder(selectedPartner.id, eventId, {
            ticketIds: ticketIds.split(',').map(Number),
            customer: {
                email: values.email,
                firstname: values.firstname ? values.firstname : null,
                lastname: values.lastname ? values.lastname : null,
                telephone: values.telephone ? values.telephone : null,
            },
            singlePdf: values.singlePdf,
            isEmail: values.isEmail,
            isZip: data.tickets.length > 100 ? true : !!values.isZip,
            note: values.note
        }, (response) => {
            saveCustomer(values)
            if (response.data?.orderId) {
                toast.success(t('ordres.created'))
                navigate(`/admin/partner/orders/${response.data.orderId}`)
            } else if (values.isEmail && response.data.status) {
                toast.success(t("tickets.ticketsWasSend", {email: values.email}))
                navigate(`/admin/partner/events/${eventId}/tickets`)
            } else if (values.isEmail && !response.data.status) {
                toast.error(t("orders.createdWithoutEmail"), {duration: 6000})
            } else if (values.isZip) {
                let name = new Date().getTime().toString()
                var blob = new Blob([response], {type: "application/zip"});
                FileSaver.saveAs(blob, name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "_") + '.zip')


                toast.success(t('tickets.ticketsWasPickup'))

                navigate(`/admin/partner/events/${eventId}/tickets`)

            } else {
                let name = new Date().getTime().toString()
                let blob = new Blob([response], {type: "application/pdf"});
                FileSaver.saveAs(blob, name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "_") + '.pdf')

                toast.success(t('tickets.ticketsWasPickup'))

                navigate(`/admin/partner/events/${eventId}/tickets`)
            }

        }, err => {
            setLoading(false)
            switchError(err, [{400: [{type: 'TICKET_ERROR'}]}], true)
        }, getConfig(values))
    }

    const getConfig = (values) => {
        if (ticketIds.split(',').map(Number).length > 100) return {}
        if (values.isEmail) return {}

        return {responseType: 'arraybuffer'}
    }


    return (
        <>
            <SectionTitle title={t('tickets.plural')} subtitle={t('tickets.pickupTickets')}/>
            <Widget title={t('basics.form')}>
                {data && data.tickets.length > 100 &&
                    <Alert size={'sm'} raised={true} borderLeft={true} withoutClose={true}
                           color={'border-purple-900 text-xs mb-4'}
                           children={<div><FiInfo style={{display: 'inline-block'}}/> <span
                               style={{verticalAlign: 'middle'}}>{t("orders.moreThan100Tickets")}</span>
                           </div>}/>
                }
                {data &&
                    <OrderForm initialValues={initialValues} onSubmit={onOrderSubmit} loading={loading}
                               noPdf={data?.noPdf}
                               ticketCount={data ? data.tickets.length : 0}/>
                }
            </Widget>
            <Widget title={t("tickets.selectedTicketsWithCount", {count: data ? data.tickets.length : 0})}>
                <ContentSpinner isSpinning={!data}>
                    <Table data={data ? data.tickets : []} columns={[
                        {
                            Header: t('tickets.enigooId'),
                            accessor: 'id'
                        },
                        {
                            Header: t('tickets.sector'),
                            accessor: 'sector'
                        },
                        {
                            Header: t('tickets.row'),
                            accessor: 'row'
                        },
                        {
                            Header: t('tickets.place'),
                            accessor: 'place'
                        },]}
                    />
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default PartnerCreateOrder;
