import React, {useEffect, useState} from 'react';
import SelectField from "../SelectField/SelectField";
import {apiGetPartnersOfSuperPartners} from "../../../pages/Admin/Partners/action";
import {useSelector} from "react-redux";


const PartnerField = ({superPartnerId, ...props}) => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(true)
    const {selectedPartner} = useSelector(state => state.user)

    useEffect(() => apiGetPartnersOfSuperPartners(selectedPartner.id, (data) => {
        setData(data)
        setLoading(false)
    }), [])

    return (
        <SelectField  {...props} options={data} loading={loading}/>
    )
}

export default PartnerField;
