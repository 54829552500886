import React, {useState} from 'react'
import CenteredLayout from "../../../components/Layouts/CenteredLayout";
import CenteredForm from "../../../components/core/CenteredForm";
import {Form} from "react-final-form";
import TextField from "../../../components/FinalForm/TextField/TextField";
import Button from "../../../components/Button/Button";
import {apiLogin, axiosSetAuthorizationToken} from "./action";
import {useDispatch} from "react-redux";
import Error from "./Error";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LanguageDropDown from "../../../components/Language/LanguageDropDown";

const Login = () => {

    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    const loginUser = (values) => {
        setLoading(true)
        setError(false)
        apiLogin(values, (data) => {
            axiosSetAuthorizationToken(data.token, dispatch)
            navigate('/admin')
        }, (err) => {
            setError(err)
            setLoading(false)

        })
    }

    return (
        <CenteredLayout>
            <div className={"fixed top-0 right-0 mr-2 mt-2"}>
                <LanguageDropDown/>
            </div>
            <CenteredForm
                title={t("login.loginUser")}
                subtitle={t("login.enterUserNamePassword")}
            >
                <Error error={error} setError={setError}/>
                <div className="flex flex-col">

                    <Form className={'test'} onSubmit={loginUser} render={({handleSubmit}) => (
                        <form className='form flex flex-wrap w-full'>
                            <div className='w-full'>
                                <TextField name={'username'} inputType={'text'} label={t('login.userName')}
                                           validate={value => !value}/>
                            </div>
                            <div className='w-full'>
                                <TextField name={'password'} inputType={'password'} label={t('login.password')}
                                           validate={value => !value}/>
                            </div>
                            <div className='text-enigoo w-full underline mb-3 text-right' style={{fontSize: 12}}>
                                <span className='cursor-pointer' onClick={() => navigate('/user/reset-password')}>{t("login.forgottenPassword")}</span>
                            </div>
                            <Button text={t("login.login")} onClick={handleSubmit} rounded={true} loading={loading}/>
                        </form>
                    )}/>
                </div>

            </CenteredForm>
        </CenteredLayout>
    )
}

export default Login
