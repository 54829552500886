import React, {Fragment} from 'react'
import ReactTooltip from "react-tooltip";
import {Badge} from "./core/Badge";

const TooltipBadge = ({id, component, badgeText, position = 'left', color = 'bg-enigoo text-white'}) => {

    return (
        <>
            <div data-tip={''} data-for={id}>
                <Badge rounded={true} color={color} size={'sm'}>{badgeText}</Badge>
            </div>

            <ReactTooltip id={id} backgroundColor='#fff' borderColor={'#62438e'} border={true} effect={'solid'} place={position} textColor={'#62438e'} >
                {component}
            </ReactTooltip>
        </>
    )
}

export default TooltipBadge;
