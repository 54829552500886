import React, {useEffect, useState} from 'react';
import SelectField from "../SelectField/SelectField";
import {apiGetPartnersOfSuperPartners} from "../../../pages/Admin/Partners/action";
import {useSelector} from "react-redux";
import {apiGetSuperPartnerEvents} from "../../../pages/Admin/Events/action";
import DateClassic from "../../Date/DateClassic";


const EventField = ({...props}) => {

    let [options, setOptions] = useState([])
    let [uncategorized, setUncategorized] = useState([])
    let [loading, setLoading] = useState(true)
    const {selectedPartner} = useSelector(state => state.user)

    useEffect(() => apiGetSuperPartnerEvents(selectedPartner.id, (data) => {
        parseOptions(data)
        setLoading(false)
    }, (err) => {
    }, {all: true}), [])


    const parseOptions = (options) => {
        let array = [];
        let _uncategorized = [];
        options.map(subject => {
            let labelOptions = [];

            subject.events.map(item => {
                labelOptions.push({label: <span><span
                        className={'text-enigoo'}>{subject.name}</span> | {item.name}<sup><DateClassic
                        dateIso={item.startTime}
                        format={'LLL'}/></sup></span>, value: item.internalId, item: item})
                _uncategorized.push({label: <span><span
                        className={'text-enigoo'}>{subject.name}</span> | {item.name}<sup><DateClassic
                        dateIso={item.startTime}
                        format={'LLL'}/></sup></span>, value: item.internalId, item: item})
            })

            array.push({
                label: subject.name,
                options: labelOptions
            })

        })

        setOptions(array)
        setUncategorized(_uncategorized)
    }

    return (
        <SelectField  {...props} customOptions={options} loading={loading}/>
    )
}

export default EventField;
