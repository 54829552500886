import React, {useEffect, useState} from "react";
import Tickets from "./Tickets";
import Customer from "./Customer";
import Button from "../../../components/Button/Button";
import {FiSend} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {CHANNEL_SET_OAUTH, CHANNEL_SET_SUBMITTED} from "../../../reducers/channel/channel";
import Event from "./Event";
import {apiCreateOrder} from "../action";
import {useNavigate} from "react-router-dom";
import NoTickets from "./NoTickets";
import {switchError} from "../../../api/api";
import {useTranslation} from "react-i18next";
import SpartaLogin from "../Sparta/SpartaLogin";
import SpartaBuy from "../Sparta/SpartaBuy";


const Order = ({data, channelId, eventId, callback}) => {

    let [loading, setLoading] = useState(false)
    let [oauth, setOAuth] = useState(null)
    let [oauthData, setOauthData] = useState(null)

    const {selectedTickets, ticketCount, type, isValid, user} = useSelector(state => state.channel)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        let oauth = localStorage.getItem('oauth')
        if (oauth) {
            setOAuth(oauth)
            dispatch({type: CHANNEL_SET_OAUTH, payload: true})
        }
        let oauthData = localStorage.getItem('oauth_data')
        if (oauthData) {
            setOauthData(JSON.parse(oauthData))
        }
    }, []);

    const singlePdf = () => {
        if (!user?.singlePdf) return false;
        if (ticketCount > 20) return false;
        return user?.singlePdf;
    }

    const onSubmit = () => {
        dispatch({type: CHANNEL_SET_SUBMITTED, payload: {isOauth: !!oauth}})
        if (isValid) {
            setLoading(true)
            apiCreateOrder(channelId, eventId, {
                customer: user,
                singlePdf: singlePdf(),
                ticketCount: ticketCount,
                ticketIds: getIds(),
                oauthLoginHash: oauth
            }, type, (data) => {
                navigate(`/channel/${channelId}/order/${data.id}?h=${data.hash}&oauth=${!!oauth}`)
            }, err => {
                setLoading(false)
                switchError(err, null, true)
                if (err.type === 'HASH_NOT_FOUND') {
                    logoutUser()
                } else {
                    callback()
                }


            })
        }
    }

    const logoutUser = () => {
        localStorage.removeItem('oauth')
        localStorage.removeItem('oauth_data')
        setOauthData(null)
        setOAuth(null)

        navigate(`/${channelId}`)
    }

    const getIds = () => {
        return selectedTickets.map(item => item.id)
    }

    const _renderText = () => {
        if (type === 0) return ticketCount ? `(${ticketCount})` : ''
        if (type === 1) return selectedTickets.length ? `(${selectedTickets.length})` : ''
    }

    const _renderCustomer = () => {
        if (!data.noPdf) {
            return (
                <>
                    <Customer ticketCount={ticketCount}/>
                    <div className='text-right'>
                        <Button onClick={onSubmit} rounded={true} icon={<FiSend/>} disabled={!isValid}
                                text={t("tickets.pickupWithCount", {count: _renderText()})} loading={loading}/>
                    </div>
                </>
            )
        }

        return <SpartaBuy oauthData={oauthData} onSubmit={onSubmit} isValid={isValid} loading={loading} hash={oauth}
                          logoutUser={logoutUser} channelUrl={channelId}/>

    }

    if (data.noPdf) {
        if (!oauth && !oauthData) {
            return <SpartaLogin channelId={channelId} eventId={eventId}/>
        }
    }


    return (
        <>
            <Event event={data.event}/>
            {data.tickets.length ?
                <>
                    <Tickets tickets={data.tickets} withSelect={data.withSelect}/>
                    {_renderCustomer()}
                </>
                :
                <NoTickets/>
            }
        </>
    )
}

export default Order
