import {api, apiErrorParser} from "../../../api/api";

export const apiGetSubjects = (success, error) => {
    api.get('api/v1/subject')
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiCreateSubject = (values, success, error) => {
    api.post('api/v1/subject', values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}
