import React, {useEffect} from "react"
import {Provider} from "react-redux";
import store from "../../store";
import Token from "./Token";
import '../../css/tailwind.css'
import '../../css/main.css'
import '../../css/animate.css'
import '../../css/_components.css'
import {useTranslation} from "react-i18next";

const Admin = () => {

    return (
        <Provider store={store}>
            <Token/>
        </Provider>
    )

}

export default Admin;
