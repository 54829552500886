import React from "react";
import CenteredForm from "../../../../components/core/CenteredForm";
import {useTranslation} from "react-i18next";
import LanguageDropDown from "../../../../components/Language/LanguageDropDown";

const VerifyError = ({error}) => {

    const {t} = useTranslation();

    const _render = () => {
        switch (error.status) {
            case 400:
                switch (error.type) {
                    case 'VERIFIED':
                        return <h6 className='text-enigoo text-center font-bold'>{t("errors.userVerifiedYet")}</h6>
                    default:
                        return <h6 className='text-enigoo text-center font-bold'>{t("errors.userNotFound")}</h6>
                }
            default:
                return <h6 className='text-enigoo text-center font-bold'>{t("errors.userNotFound")}</h6>
        }
    }

    if (!error) return null

    return (
        <CenteredForm title={''} subtitle={''}>
            <div className={"fixed top-0 right-0 mr-2 mt-2"}>
                <LanguageDropDown/>
            </div>
            {_render()}
        </CenteredForm>
    )


}

export default VerifyError;
