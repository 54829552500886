import {api, apiErrorParser} from "../../../../api/api";

export const apiGetSuperPartnerSummary = (superPartnerId, eventIds, success, error) => {
    api.get('api/v1/super-partner/' + superPartnerId + '/summary', {params: {eventIds: eventIds}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetSuperPartnerSummaryDetailed = (superPartnerId, eventIds, success, error) => {
    api.get('api/v1/super-partner/' + superPartnerId + '/summary-detailed', {params: {eventIds: eventIds}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}
