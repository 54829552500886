import React from 'react'
import Sidebar from "./Sidebar/Sidebar";
import {useSelector} from "react-redux";
import Navbar from "./Navbar/Navbar";
import '../../css/layouts/layout-1.css'
import {Toaster} from "react-hot-toast";

const MainLayout = ({children}) => {

    const {isCollapsed} = useSelector(state => state.config)

    return (
        <div
            data-layout={'layout-1'}
            data-collapsed={isCollapsed}
            data-background={'light'}
            data-navbar={'light'}
            data-left-sidebar={'light'}
            data-right-sidebar={'light'}
            className={'default-mode text-sm'}>

            <div className="wrapper">
                <Sidebar/>
                <div className="main w-full bg-grey-50 text-grey-900 dark:bg-grey-900 dark:text-white">
                    <Navbar />
                    <div className="min-h-screen w-full p-4">{children}</div>
                </div>
            </div>
        </div>
    )
}

export default MainLayout;
