import React, {useState, useEffect, useRef} from 'react'
import {FiLogOut, FiUser} from "react-icons/fi";
import {api, apiErrorParser} from "../../api/api";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {USER_RESET} from "../../reducers/admin/user";
import {useTranslation} from "react-i18next";

const Dropdown = () => {
    const [hidden, setHidden] = useState(true)

    const buttonRef = useRef(null)
    const dropdownRef = useRef(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                hidden ||
                buttonRef.current.contains(event.target) ||
                dropdownRef.current.contains(event.target)
            ) {
                return false
            }
            setHidden(!hidden)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [hidden, dropdownRef, buttonRef])

    const handleDropdownClick = () => {
        setHidden(!hidden)
    }

    const onLogout = () => {
        apiLogout(() => {
            toast.success(t('basics.wasLogout'))
            dispatch({type: USER_RESET})
            navigate('/admin')
            localStorage.removeItem('Authorization')
            localStorage.removeItem('selectedPartner')
            localStorage.removeItem('selectedPartnerType')
        })
    }

    return (
        <div className="relative">
            <button className="flex h-16 w-8 rounded-full ml-2 relative mr-4" onClick={handleDropdownClick}
                    ref={buttonRef}>
                <span className="absolute top-0 left-0 pt-4">
                  <div className="h-8 w-8 rounded-full shadow flex justify-center items-center">
                      <FiUser size={20}/> </div>
                </span>
            </button>
            <div ref={dropdownRef}
                 className={`dropdown absolute top-0 right-0 mt-16 ${hidden ? '' : 'open'}`}>
                <div className="dropdown-content w-48 bottom-end">
                    <div className="flex flex-col w-full">
                        <ul className="list-none">
                            <li className="dropdown-item">
                                <div
                                    onClick={onLogout}
                                    className="flex flex-row items-center justify-start h-10 w-full px-2 cursor-pointer">
                                    <FiLogOut/> <span className='mx-2'>{t("basics.logout")}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const apiLogout = (success, error) => {
    api.post('api/v1/user/logout', {})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export default Dropdown
