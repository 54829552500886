import React, {useEffect, useState} from "react";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import {Form} from "react-final-form";
import {apiGetUsers, apiUsersToSuperPartner} from "../action";
import {apiGetSuperPartners} from "../../SuperPartners/action";
import SelectField from "../../../../components/FinalForm/SelectField/SelectField";
import Button from "../../../../components/Button/Button";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SuperPartner = () => {

    let [users, setUsers] = useState(null)
    let [partners, setPartners] = useState(null)
    let [loading, setLoading] = useState(false)

    const {t} = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        apiGetUsers(data => setUsers(parseUsers(data)))
        apiGetSuperPartners(data => setPartners(data))
    }, [])

    const parseUsers = (data) => data.map(item => {
        return {id: item.id, name: item.username}
    })


    const onSubmit = (values) => {
        setLoading(true)
        apiUsersToSuperPartner(values.superPartnerId, {userIds: values.userIds}, () => {
            toast.success(t('basics.saved'))
            navigate('/admin/system/users')
        })
    }

    return (
        <>
            <SectionTitle title={t('basics.system')} subtitle={t("users.assignUserToSuperPartner")}/>
            <Widget>
                <Form onSubmit={onSubmit} render={({handleSubmit}) => (
                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                            <div className='w-full'>
                                <SelectField name={'userIds'} label={t('users.plural')} isMulti={true} validate={true}
                                             loading={!users} placeholder={t('placeholders.user')}
                                             options={users}/>
                            </div>
                            <div className='w-full'>
                                <SelectField name={'superPartnerId'} label={'Super partner'} isMulti={false}
                                             validate={true}
                                             loading={!partners} placeholder={t('placeholders.superPartner')}
                                             options={partners}/>
                            </div>
                        </div>
                        <div className='text-right'>
                            <Button rounded={true} size={'default'} onClick={handleSubmit} text={t('basics.save')}
                                    loading={loading}/>
                        </div>
                    </>
                )}/>
            </Widget>
        </>
    )
}

export default SuperPartner
