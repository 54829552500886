import React, {useEffect, useState} from "react";
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import Table from "../../../../components/Table/Table";
import ContentSpinner from "../../../../components/core/Spinner/ContentSpinner";
import {apiGetSubjects} from "../action";
import {useTranslation} from "react-i18next";


const SubjectList = () => {

    let [subjects, setSubjects] = useState(false)

    const {t} = useTranslation()

    useEffect(() => {
        apiGetSubjects(data => {
            setSubjects(data)
        })
    }, [])

    return (
        <>
            <SectionTitle title={t('basics.system')} subtitle={t('subjects.plural')}/>
            <Widget>
                <ContentSpinner isSpinning={!subjects}>
                    <Table
                        data={subjects}
                        columns={[
                            {Header: t('basics.name'), accessor: 'name'},
                            {Header: t('subjects.subDomainEnigoo'), accessor: 'subject'},
                        ]}
                    />
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default SubjectList
