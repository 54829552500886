export const USER_RESET = "USER_RESET";
export const USER_SET_TOKEN = "USER_SET_TOKEN";
export const USER_INIT = "USER_INIT";
export const USER_SET_PARTNER = "USER_SET_PARTNER";


export const TYPE_SUPER_PARTNER = "SUPER_PARTNER";
export const TYPE_PARTNER = "PARTNER";

export default function reducer(
    state = {
        token: null,
        user: null,
        selectedPartner: null,
        hasMultiplePartners: false
    },
    action
) {
    switch (action.type) {
        case USER_SET_TOKEN:
            return {
                ...state,
                token: action.payload,

            }
        case USER_INIT:
            return {
                ...state,
                user: action.payload,
                hasMultiplePartners: isMultiplePartners(action.payload)
            }
        case USER_SET_PARTNER: {
            return {
                ...state,
                selectedPartner: action.payload
            }
        }
        case USER_RESET: {
            return {
                token: null,
                user: null,
                selectedPartner: null,
                hasMultiplePartners: false
            }
        }
    }

    return state
}


const isMultiplePartners = (payload) => {
    return (payload.superPartners.length + payload.partners.length) > 1
}
