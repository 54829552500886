import React from "react";
import PropTypes from 'prop-types';
import {Field} from "react-final-form"
import {useTranslation} from "react-i18next";

const TextField = ({...props}) => {

    const {t} = useTranslation()

    const _renderError = (meta) => {
        if (props.instantRenderError) {
            if (meta.error) {
                return <div className='form-error'>{meta.error === true ? t("errors.requireField") : meta.error}</div>
            }
        } else if (props.renderErrorWithoutSubmit) {
            if (meta.modified && meta.error) {
                return <div className='form-error'>{meta.error === true ? t("errors.requireField") : meta.error}</div>
            }
        } else if (meta.touched && meta.error) {
            return <div className='form-error'>{meta.error === true ? t("errors.requireField") : meta.error}</div>
        }

        return null;
    }

    const _renderClassName = (meta) => {
        let className = `form-input `
        if (props.instantRenderError) {
            if (meta.error) {
                className += 'border-red-500 '
            }
        }
        if (props.renderErrorWithoutSubmit) {
            if (meta.modified && meta.error) {
                className += 'border-red-500 '
            }
        } else if (meta.touched && meta.error) {
            className += 'border-red-500 '
        }

        return className;
    }

    return (
        <Field {...props} name={props.name} render={({input, meta}) => (
            <div className='form-element'>
                {props.label && <div className="form-label">{props.label}{(props.validate ? "*" : "")}</div>}
                {props.textArea ?
                    <textarea
                        disabled={props.disabled}
                        value={input.value}
                        onChange={(e) => input.onChange(e.target.value)}
                        className={_renderClassName(meta)}
                    />
                    :
                    <input
                        disabled={props.disabled}
                        value={input.value}
                        onChange={(e) => input.onChange(e.target.value)}
                        type={props.inputType}
                        className={_renderClassName(meta)}
                    />
                }
                {_renderError(meta)}
            </div>
        )}/>

    )
}

TextField.defaultProps = {
    inputType: 'text',
    label: null,
    renderErrorWithoutSubmit: false,
    instantRenderError: false
}

TextField.propTypes = {
    inputType: PropTypes.string,
    label: PropTypes.string,
    renderErrorWithoutSubmit: PropTypes.bool,
    instantRenderError: PropTypes.bool,
    textArea: PropTypes.bool
}

export default TextField;
