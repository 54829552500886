import moment from "moment";
import React from "react";
import ReactExport from "react-export-excel";
import {useTranslation} from "react-i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportDetailed = ({data, exportName = 'export'}) => {

    const {t} = useTranslation()

    const getDataSet = (_data) => {

        let dataSet = [];

        dataSet.push({
            columns: [_data.eventName, moment(_data.eventDate).format('DD.MM.YYYY HH:mm')],
            data: []
        })


        dataSet.push({
            columns: [t('partners.single'), t('distributionChannels.single'), t('tickets.pickup'), t('basics.firstname'), t('basics.surname'), t('basics.email'), t('basics.phone'), t('tickets.distributionsDate'), t('tickets.entry'), t('tickets.sector'), t('tickets.row'), t('tickets.place'), t('tickets.category'), 'Order ID (ENIGOO)', 'Ticket ID (ENIGOO)'],
            data: getTickets(_data.data)
        })


        return dataSet;
    }

    const getTickets = (_data) => {
        let array = [];
        _data.map(item => {
            let inner = [];
            inner.push(item.partner ? item.partner : '-')
            inner.push(item.channelName ? item.channelName : '-')
            inner.push(item.type === 1 ? t('users.single') : t('basics.quest'))
            inner.push(item.firstname ? item.firstname : '-')
            inner.push(item.lastname ? item.lastname : '-')
            inner.push(item.email ? item.email : '-')
            inner.push(item.telephone ? item.telephone : '-')
            inner.push(moment(item.dateSold).format('DD.MM.YYYY HH:mm'))
            inner.push(item.entrance ? t('basics.yes') : t('basics.no'))
            inner.push(item.sector)
            inner.push(item.row)
            inner.push(item.place)
            inner.push(item.category)
            inner.push(item.orderId)
            inner.push(item.ticketId)

            array.push(inner);
        })

        return array;
    }

    if (!data) return null;
    return (
        <ExcelFile filename={exportName + '_' + new Date().getTime()}
                   hideElement={true}>
            {data.map((item, key) => (
                <ExcelSheet dataSet={getDataSet(item)} name={`List ${key + 1}`}/>
            ))}

        </ExcelFile>
    )
}

export default ExportDetailed
