import React from 'react';
import {Form} from "react-final-form";
import SwitchField from "../../../../components/FinalForm/Switch/SwitchField";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import * as EmailValidator from "email-validator";
import Button from "../../../../components/Button/Button";
import {FiFile, FiSend} from "react-icons/fi";
import {OnChange} from "react-final-form-listeners";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SpartaButton from "../../../../components/Button/SpartaButton";

const OrderForm = ({onSubmit, loading, ticketCount, initialValues, noPdf}) => {

    const {user} = useSelector(state => state.user)

    const {t} = useTranslation()


    const _renderForm = (values, form, handleSubmit) => {
        if (noPdf) {
            if (user.data) return (
                <div className='text-right'>
                    <Button loading={loading} onClick={handleSubmit} rounded={true}
                            text={`${t("tickets.pickupTicketsSparta")} (${user.data.email})`}/>
                </div>
            )

            return <SpartaButton/>

        }

        return (
            <>
                {ticketCount <= 100 &&

                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                            <SwitchField name={'isEmail'} label={t('orders.sendToMail')}
                                         disabled={values.isZip}/>
                        </div>
                        {values.isEmail &&
                            <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                                <SwitchField name={'singlePdf'} disabled={ticketCount > 20}
                                             label={t('orders.singleFile')}/>
                            </div>
                        }
                        <OnChange name={'isZip'}>
                            {(value) => {
                                if (value && values.isEmail) {
                                    form.change('isEmail', false)
                                }
                            }}
                        </OnChange>
                        <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                            <SwitchField name={'isZip'} label={t('orders.ticketForSingleFile')}/>
                        </div>
                    </>
                }
                <>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                        <TextField label={'E-mail'} inputType={'email'} name={'email'} validate={value => {
                            if (!value) return true;
                            if (!EmailValidator.validate(value)) return t("errors.fieldMustBeEmail")
                            return false;
                        }}/>
                        <TextField label={t('basics.firstname')} inputType={'text'} name={'firstname'}
                                   validate={value => !value}/>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                        <TextField label={t('basics.surname')} inputType={'text'} name={'lastname'}
                                   validate={value => !value}/>
                        <TextField label={t('basics.phone')} inputType={'text'} name={'telephone'}/>
                    </div>
                </>
                <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                    <TextField label={t("orders.noteForOrders")} textArea={true} name={'note'}/>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-1 lg:gap-4'>
                    <SwitchField name={'saveCustomer'} label={t("orders.saveDataForNextOrder")}/>
                </div>
                <div className='text-right'>
                    <Button loading={loading} onClick={handleSubmit} rounded={true}
                            icon={values?.isEmail ? <FiSend/> : <FiFile/>}
                            text={values?.isEmail ? t("tickets.pickupTicketsAndSendMail") : t('tickets.pickupTicketsAndDownload')}/>
                </div>
            </>
        )
    }

    return (
        <Form onSubmit={onSubmit} initialValues={{singlePdf: false, isEmail: false, ...initialValues}}
              render={({handleSubmit, values, form}) => (
                  _renderForm(values, form, handleSubmit)
              )}/>
    )
}

export default OrderForm;
