import React from "react";
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import {Field} from "react-final-form";

const SwitchField = ({...props}) => {

    return (
        <Field {...props} name={props.name} render={({input, meta}) => (
            <div className='form-element'>
                {props.label && <div className="form-label">{props.label}</div>}
                <Switch {...input} disabled={props.disabled} checked={input.value} onChange={(value) => {
                    props.onCallbackChange && props.onCallbackChange(value)
                    input.onChange(value)
                }} />
            </div>
        )}/>
    )
}

export default SwitchField
