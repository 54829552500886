import React from "react";
import Button from "../../../components/Button/Button";
import {HiOutlineTicket} from "react-icons/hi";
import DateClassic from "../../../components/Date/DateClassic";
import {useTranslation} from "react-i18next";


const Events = ({data, onEventClick}) => {

    const {t} = useTranslation()

    const _renderEvents = () => {
        if(data.length === 0) return <h6 className='text-enigoo text-center'>{t("events.noEventsAvailable")}</h6>
        return data.map((item, key) => (
            <div className='flex border border-grey-200 rounded p-4' key={key + "_item"}>
                <div className='flex-col flex-grow'>
                    <div className='text-enigoo text-lg'>{item.name}</div>
                    <div className={'text-xs'}><DateClassic dateIso={item.startTime} format={'LLL'} />{/* - <span className='text-enigoo font-bold'>{item.ticketCount} vstupenek dostupných</span>*/}</div>
                </div>
                <div className='flex justify-center'>
                    <Button onClick={() => onEventClick(item.id)}  icon={<HiOutlineTicket size={30} />} outline={true}  />
                </div>
            </div>
        ))
    }

    return (
        <div>
            <div>
                {_renderEvents()}
            </div>
        </div>
    )
}

export default Events
