import React, {useEffect, useState} from 'react';
import SectionTitle from "../../../components/core/SectionTitle";
import Widget from "../../../components/core/Widget";
import ContentSpinner from "../../../components/core/Spinner/ContentSpinner";
import Table from "../../../components/Table/Table";
import {apiGetPartnerEvents, apiGetSuperPartnerEvents} from "./action";
import {useSelector} from "react-redux";
import DateClassic from "../../../components/Date/DateClassic";
import Button from "../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import {TYPE_PARTNER, TYPE_SUPER_PARTNER} from "../../../reducers/admin/user";
import {useTranslation} from "react-i18next";


const Events = () => {

    let [events, setEvents] = useState(null)

    const {selectedPartner} = useSelector(state => state.user)
    const {t} = useTranslation();
    const navigate = useNavigate()

    useEffect(() => {
        if (selectedPartner.type === TYPE_SUPER_PARTNER) {
            apiGetSuperPartnerEvents(selectedPartner.id, (data) => setEvents(data))
        } else if (selectedPartner.type === TYPE_PARTNER) {
            apiGetPartnerEvents(selectedPartner.id, (data) => setEvents(data))
        }
    }, [])

    const _renderButton = (event, item) => {
        if (selectedPartner.type === TYPE_SUPER_PARTNER) return <Button
            onClick={() => navigate('/admin/super-partner/events/tickets?eventId=' + event.id + '&subjectId=' + item.id)}
            text={t('basics.detail')} rounded={true}/>
        else if (selectedPartner.type === TYPE_PARTNER) return <Button
            onClick={() => navigate(`/admin/partner/events/${event.localId}/tickets`)}
            text={t('basics.detail')} rounded={true}/>
    }

    const _renderTables = () => {
        if (!events) return null
        return events.map((item, key) => (
            <div key={key}>
                <h4>{item.name}</h4>
                <Table
                    data={item.events} emptyText={t('events.noEvents')}
                    columns={[
                        {Header: t('events.single'), id: 'name', accessor: event => _renderImageName(event)},
                        {Header: t('basics.date'), id: 'date', accessor: event => <DateClassic dateIso={event.startTime}/>},
                        {
                            Header: '',
                            id: 'button',
                            accessor: event => _renderButton(event, item)
                        },
                    ]}
                />
            </div>
        ))
    }

    const _renderImageName = (event) => {
        return <div>{event.image &&
            <img src={event.image} alt={'logo'} className='inline-block align-middle' width={30}/>} {event.name}</div>
    }

    return (
        <>
            <SectionTitle title={t('events.plural')} subtitle={t('events.available')}/>
            <Widget>
                <ContentSpinner isSpinning={!events}>
                    <>
                        {_renderTables()}
                    </>
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default Events;
