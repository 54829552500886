import React, {useState} from 'react'
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {apiCreateSubject} from "../action";
import {useNavigate} from "react-router-dom"
import toast from "react-hot-toast"
import {switchError} from "../../../../api/api";
import {useTranslation} from "react-i18next";


const SUBJECT_EXISTS = "SUBJECT_EXISTS"
const ENIGOO_ERROR = "ENIGOO_ERROR"

const CreateSubject = () => {

    let [loading, setLoading] = useState(false)

    const {t} = useTranslation()
    const navigate = useNavigate()

    const onSubmit = (values) => {
        setLoading(true)
        apiCreateSubject(values, () => {
            navigate('/admin/system/subjects')
            toast.success(t("subjects.created"))
        }, err => {
            setLoading(false)
            switchError(err, [], true)
        })
    }

    return (
        <>
            <SectionTitle title={t('basics.system')} subtitle={t("subjects.add")}/>
            <Widget>

                <Form onSubmit={onSubmit} render={({handleSubmit}) => (
                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                            <div className='w-full'>
                                <TextField label={t('subjects.internalName')} name={'name'} inputType={'text'}
                                           validate={value => !value}/>
                            </div>
                            <div className='w-full'>
                                <TextField label={t('subjects.subDomainEnigoo')} name={'subject'} inputType={'text'}
                                           validate={value => !value}/>
                            </div>
                        </div>
                        <div className='text-right'>
                            <Button rounded={true} size={'default'} onClick={handleSubmit} text={t('basics.save')}
                                    loading={loading}/>
                        </div>

                    </>
                )}/>
            </Widget>
        </>
    )
}

export default CreateSubject;
