import React, {useState} from 'react'
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import {useNavigate, useSearchParams} from "react-router-dom";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {Form} from "react-final-form";
import * as EmailValidator from "email-validator";
import {apiConnectPartnerToUser} from "../action";
import {switchError} from "../../../../api/api";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";

const PartnerToUser = () => {

    let [loading, setLoading] = useState(false)

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {t} = useTranslation()

    const id = searchParams.get('id')
    const name = searchParams.get('name')


    const onSubmit = (values) => {
        setLoading(true)
        apiConnectPartnerToUser(id, values, () => {
            toast.success(t("users.userWithIdToPartner",{id:values.username}))
            navigate('/admin/super-partner/partners')
        }, err => {
            setLoading(false)
            switchError(err, [], true)
        })
    }

    if (!id || !name) return null

    return (
        <>
            <SectionTitle title={t("partners.nameOf",{name:name})} subtitle={t('users.assign')}/>
            <Widget>
                <Form onSubmit={onSubmit} render={({handleSubmit}) => (
                    <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                            <div className='w-full'>
                                <TextField label={t('users.emailOf')} name={'username'} inputType={'email'}
                                           validate={value => {
                                               if (!value) return true;
                                               if (!EmailValidator.validate(value)) return t("errors.fieldMustBeEmail")
                                               return false;
                                           }}/>
                            </div>
                        </div>
                        <div className='text-right'>
                            <Button rounded={true} size={'default'} onClick={handleSubmit} text={t('basics.save')}
                                    loading={loading}/>
                        </div>

                    </>
                )}/>
            </Widget>
        </>
    )
}

export default PartnerToUser;
